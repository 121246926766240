<template>
    <div v-if="!$vuetify.breakpoint.xs" cols="12" class="mt-3">
        <v-card class="pa-5" color="#272727" style="border-radius: 10px;">
            <v-data-table :loading="isLoading" :headers="headers" :items="wallets" :items-per-page="20"
                @click:row="rowClicked($event)" class="transparent">

                <template v-slot:item.name="{ item }">
                    <div class="py-3 d-flex align-center">
                        <div class="mr-3">
                            <v-avatar color="white" size="30">
                                <v-img :src="item.image"></v-img>
                            </v-avatar>
                        </div>

                        <div class="mr-2 d-flex align-center">
                            <!-- <span class="mr-2" style="font-size:16px;">{{ item.coin_pair_name }}</span><br /> -->
                            <span class="">{{ (item.name) }}</span>
                            <!-- <span style="font-size: 14px;"> {{ item.date }}</span><br /> -->
                        </div>
                    </div>
                </template>
            </v-data-table>
        </v-card>
    </div>

    <!-- mobile view -->
    <div v-else cols="12">

        <div class="mt-5">
            <SearchBarComponent :search.sync="search" placeholder="Search Coin" />
        </div>

        <div v-if="filteredData.length <= 0">
            <div class="d-flex align-center justify-center mt-10" style="height: 100%">
                <div class="d-flex flex-column align-center justify-center">
                    <v-icon x-large color="#b7bdc6">
                        mdi mdi-note-search-outline
                    </v-icon>
                    <span style="font-size: 12px; color: #b7bdc6"> {{ $t('wallet.noRecords') }} No Records Found</span>
                </div>
            </div>
        </div>

        <!-- Loading indicator -->
        <div v-if="isLoading" class="text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>

        <v-row v-else class="mt-5" v-for="{ name, image, usdt_value, margin_balance, upnl, available } in filteredData">
            <div class="">
                <div class=" d-flex align-center">
                    <v-avatar size="30">
                        <img :src="image" alt="coin logo">
                    </v-avatar>
                    <span class="ml-2" style="font-size: 1rem; font-weight: 500">{{ name }}</span>
                </div>
            </div>

            <v-col cols="6">
                <div class="d-flex flex-column">
                    <span style="font-size: 12px; color: #b7bdc6;">{{ $t('wallet.walletBalance') }}</span>
                    <span style="font-size: 14px;">{{ usdt_value }}</span>
                </div>

                <div class="mt-2 d-flex flex-column">
                    <span style="font-size: 12px; color: #b7bdc6;">{{ $t('wallet.margin') }}</span>
                    <span style="font-size: 14px;">{{ margin_balance }}</span>
                </div>
            </v-col>
            <v-col cols="6">
                <div class="d-flex flex-column">
                    <span style="font-size: 12px; color: #b7bdc6;">{{ $t('wallet.unrePnl') }}</span>
                    <span style="font-size: 14px;">{{ upnl }}</span>
                </div>

                <div class="mt-2 d-flex flex-column">
                    <span style="font-size: 12px; color: #b7bdc6;">{{ $t('wallet.forTransfer') }}</span>
                    <span style="font-size: 14px;">{{ available }}</span>
                </div>

            </v-col>
            <v-divider color="#b7bdc6"></v-divider>
        </v-row>
    </div>
</template>

<script>


import SearchBarComponent from '@/components/ui/SearchBarComponent.vue'

export default {

    components: {
        SearchBarComponent,
    },

    data() {
        return {
            search: "",
            isLoading: "",
            wallets: [],
            headers: [

                {
                    value: "name",
                    text: "Asset",
                    align: "start",
                    sortable: true,
                },

                {
                    value: "usdt_value",
                    text: "Wallet Balance",
                    align: "end",
                    sortable: true,
                },

                {
                    value: "margin_balance",
                    text: "Margin Balance",
                    sortable: true,
                    align: "end",
                },

                {
                    value: "upnl",
                    text: "Unrealized Pnl",
                    sortable: true,
                    align: "end",
                },

                {
                    value: "available",
                    text: " Available for Transfer",
                    sortable: true,
                    align: "end",
                },

                // {
                //     value: "actions",
                //     text: "Actions",
                //     sortable: true,
                //     align: "end",
                // }
            ],
        }
    },

    methods: {
        fetchAssets() {
            this.$axios
                .get("https://api.ubcoins.co/api/v3/future-wallet")
                .then((response) => {
                    try {
                        this.isLoading = true;
                        // Check if response data and response data's wallet property exist
                        if (response.data && response.data.wallet) {
                            this.wallets = response.data.wallet;
                            console.log(this.wallets);
                        } else {
                            console.log("Response data or wallet property is missing");
                        }
                    } catch (error) {
                        console.log(error);
                    } finally {
                        this.isLoading = false; // Corrected typo
                    }
                });
        },
    },
    mounted() {
        this.fetchAssets()
    },
    computed: {
        filteredData() {
            if (!this.search) {
                return this.wallets;
            }
            const searchTerm = this.search.toLowerCase();
            return this.wallets.filter(item => item.name.toLowerCase().includes(searchTerm))
        }
    }
}
</script>