<template>
    <v-row align="center" class="ma-0" @click="openDetails()">
        <v-col style="margin: 0 0 0 0 !important">
            <div class="d-flex justify-content-start align-items-center">
                <strong>{{ name }}</strong>
                <span class="caption grey--text darken-2"></span>
            </div>
        </v-col>
        <v-col class="text-right" style="line-height: normal; margin: 0 0 0 0 !important">
            <div class="d-flex justify-content-end align-items-center">
                <v-card :class="percentColor" height="40px" min-width="100px">
                    {{ percentWithSign }}
                </v-card>
            </div>
        </v-col>
        <v-dialog v-if="this.apr != 'Not available'" transition="dialog-bottom-transition" v-model="detailsDialog"
            fullscreen>
            <v-card>
                <v-toolbar>
                    <v-btn icon color="secondary" @click="detailsDialog = false">
                        <icon icon="material-symbols:arrow-back" width="24" height="24"></icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>{{ name }} Subscribe</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon> </v-btn>
                </v-toolbar>
                <v-card-text class="mt-12">
                    <v-form ref="earnForm" lazy-loading>
                        <v-row>
                            <label>Duration(Days)</label>
                            <v-btn class="mt-1" color="secondary" outlined small>Flexible</v-btn>
                            <!-- <v-item-group mandatory v-model="selectedDays">
                                <v-row>
                                    <v-col
                                        v-for="day in daysOptions"
                                        :key="day"
                                        cols="3"
                                    >
                                        <v-item
                                            :value="day"
                                            v-slot="{ active, toggle }"
                                        >
                                            <v-card
                                                :color="active ? 'primary' : ''"
                                                class="d-flex align-center"
                                                dark
                                                height="20"
                                                @click="toggle"
                                            >
                                                <v-scroll-y-transition>
                                                    <div
                                                        class="flex-grow-1 text-center"
                                                    >
                                                        {{ day }}
                                                    </div>
                                                </v-scroll-y-transition>
                                            </v-card>
                                        </v-item>
                                    </v-col>
                                </v-row>
                            </v-item-group> -->
                        </v-row>
                        <v-row>
                            <v-col class="pr-0" cols="9">
                                <v-text-field light v-model="amount" rounded background-color="white"
                                    :rules="rules()"></v-text-field>
                            </v-col>
                            <v-col class="pl-0" cols="3">
                                <v-btn class="mt-3" color="secondary" rounded @click="maxAmount()">
                                    Max
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <p class="sub-title1 mb-0 white--text">
                                    <strong class="white--text">Available: {{ balance }}
                                        {{ name }}</strong>
                                </p>
                                <p class="sub-title1 mb-0 grey--text">
                                    Amount Limit
                                </p>
                                <p class="sub-title1 mb-0 grey--text">
                                    Minimum:
                                    <strong class="white--text">{{ minimum }} {{ name }}</strong>
                                </p>
                                <p class="sub-title1 mb-0 grey--text">
                                    Maximum:
                                    <strong class="white--text">{{ maximum }} {{ name }}</strong>
                                </p>
                                <p class="sub-title1 mb-0 grey--text">
                                    Total Personal Quota:
                                    <strong class="white--text">{{ totalQuota }} {{ name }}</strong>
                                </p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <label class="h6">Summary</label>
                                <v-card>
                                    <v-card-text>
                                        <div class="d-flex justify-content-between">
                                            <p class="h6">Real-Time APR</p>
                                            <p class="h6 greenPrimary--text">
                                                {{ apr }} ~ {{ apr * 4 }}%
                                            </p>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                            <p class="h6">Est. Daily Rewards</p>
                                            <p class="h6 greenPrimary--text">
                                                --
                                            </p>
                                        </div>
                                        <p class="caption">
                                            *APR Does not mean the actual or
                                            predicted returns in fiat currency
                                        </p>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-row justify="center" no-gutter>
                        <!-- <v-col class="pr-1 ml-2 mb-1"
                            ><v-btn
                                block
                                large
                                color="primary"
                                @click="invest('FLEXI_LOCK')"
                                >Flexi lock
                            </v-btn></v-col
                        > -->
                        <v-col class="pl-1 mr-2 mb-1"><v-btn class="black--text" block large color="secondary"
                                @click="invest('STAKE_LOCK')">CONFIRM
                            </v-btn></v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
export default {
    props: {
        id: String,
        name: String,
        image: String,
        apr: String,
        earn: Array,
    },
    data() {
        return {
            detailsDialog: false,
            selectedDays: 5,
            daysOptions: ["Flexible", 5, 10, 15, 30, 60, 120, 180],
            amount: 0,
            balance: 0,
            totalQuota: 0,
        };
    },
    computed: {
        percentColor() {
            //Return the color based on the percent value if positive or negative
            return this.apr != "Not available"
                ? "percent-card greenPrimary--text d-flex align-center justify-center transparent elevation-0"
                : "percent-card redPrimary--text d-flex align-center justify-center transparent elevation-0";
        },
        percentWithSign() {
            //Return the percent value with a sign
            return this.apr != "Not available"
                ? "APR " + this.apr + "%"
                : "Not Available";
        },
        minimum() {
            return parseFloat(this.earn[0].minimum_amount);
        },
        maximum() {
            return parseFloat(this.earn[0].maximum_amount);
        },
    },
    methods: {
        rules() {
            return [
                (v) => !!v || "Amount is required",
                (v) =>
                    v >= this.minimum ||
                    "Amount must be greater than or equal minimum",
                (v) => v <= this.maximum || "Amount must be less than maximum",
                (v) =>
                    v <= this.balance ||
                    "Amount must be less than or equal balance",
            ];
        },
        maxAmount() {
            this.amount = this.balance;
        },
        openDetails() {
            this.$axios
                .get("https://api.ubcoins.co/api/v3/wallet-balance", {
                    params: {
                        name: this.name,
                    },
                })
                .then((response) => {
                    this.balance = response.data.wallet.wallet_balance;
                    if (this.apr != "Not available") this.detailsDialog = true;
                });
        },
        invest(type) {
            if (this.$refs.earnForm.validate()) {
                this.$axios
                    .post("https://api.ubcoins.co/api/v2/earn/store", {
                        earn_id: this.earn[0].id,
                        days: this.selectedDays,
                        amount: this.amount,
                        status: type,
                    })
                    .then((response) => {
                        if (response.data.message == "success") {
                            this.$swal({
                                title: "Success",
                                text: "Investment successful",
                                icon: "success",
                                timer: 3000,
                                buttons: false,
                            });
                            this.$router.push("/home");
                        } else {
                            this.$swal({
                                title: "Error",
                                text: "Something went wrong",
                                icon: "error",
                                timer: 3000,
                                buttons: false,
                            });
                        }
                    });
            }
        },
    },
};
</script>
<style>
.percent-card {
    font-weight: 550;
}
</style>
