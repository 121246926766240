<template>
    <v-container>
        <v-row class="mt-10">
            <v-col cols="12" align="end">
                <h4 class="mt-5" style="color:#fe5e02">Add Miner</h4>
                <v-divider></v-divider>
            </v-col>
            
            <v-row class="mt-2">

                <v-col cols="6">
                    <h2 style="color:#fed500">
                        Available Miners
                    </h2>
                    <p>
                        Explore and choose from our selection of high-performance
                        mining rig, tailored that suits your specific needs.
                    </p>
                </v-col>

                <v-col cols="6">
                    <v-sheet
                    color="#fed500"
                    style="border-radius: 20px;"
                    class="mx-auto pa-8 mt-5">
                        <div class="d-flex align-center">
                            <span style="color: #1f2028;">Available Balance</span>
                            <v-btn class="ml-2" color="primary" icon>
                                <v-icon color="#1f2028">mdi-eye</v-icon>
                            </v-btn> 
                        </div>

                        <div class="d-flex">
                            <h2 style="color: #1f2028; font-weight: 700;">USDT 12,30.<small>00</small></h2>
                        </div>
                    </v-sheet>
                </v-col>
            </v-row>

            <v-row class="mt-5">
                <v-col cols="6">
                    <v-card color="#00000000" class=" pa-5" style="border-radius: 20px; border: 1px solid #9493AC">
                        <v-text>
                            <div class="d-flex align-center justify-space-between">
                                
                            <div v-if="!show">image</div>

                                <div>
                                    <span style="color:#fed500">N2</span>
                                    <br>
                                    <span>(Experience)</span>
                                </div>

                                <div>
                                    <v-btn @click="toggleShow()" class="ml-2" color="primary" icon="mdi-chevron-down">
                                        <v-icon v-if="show" color="primary">mdi-chevron-up</v-icon>
                                        <v-icon v-else color="primary">mdi-chevron-down</v-icon>
                                    </v-btn> 
                                </div>
                            </div>
                        </v-text>

                        <v-expand-transition>
                            <div v-show="show">
                                <v-divider></v-divider>

                                <v-img
                                    height="150px"
                                    src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
                                    cover
                                >
                                </v-img>
                                
                                <v-row class="mt-5">
                                    <v-col cols="6">
                                        <div class="d-flex flex-column">
                                            <span>Least Term</span>
                                            <span>90 Days</span>
                                        </div>
                                    </v-col>

                                    <v-col cols="6">
                                        <div class="d-flex flex-column">
                                            <span>Upper Limit</span>
                                            <span>1/Platforms</span>
                                        </div>
                                    </v-col>

                                    <v-col cols="6">
                                        <div class="d-flex flex-column">
                                            <span>Days Remaining</span>
                                            <span>30 Days</span>
                                        </div>
                                    </v-col>

                                    <v-col cols="6">
                                        <div class="d-flex flex-column">
                                            <span>Output</span>
                                            <span>0.81 TRX/Days</span>
                                        </div>
                                    </v-col>

                                </v-row>

                                <v-card-actions>
                                    <v-btn 
                                        class="mt-5"
                                        color="primary"
                                        large
                                        block
                                        >
                                    RENT FOR MINING
                                    </v-btn>
                                </v-card-actions>
                            </div>
                        </v-expand-transition>

                    </v-card>
                </v-col>

            </v-row>

            
        </v-row>
    </v-container>
</template>

<script>
export default {
  
    data() {
    return {
      show: false,
    };
  },

  methods: {
    toggleShow() {
      this.show = !this.show;
    },
  },
};

</script>