<template>
  <v-row justify="center">
    <v-col cols="8">
      <v-toolbar class="transparent elevation-0">
        <v-btn icon color="secondary" @click="goBack">
          <icon icon="material-symbols:arrow-back" width="24" height="24"></icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title>Analysis</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon> </v-btn>
      </v-toolbar>
      <main-earn-analysis-coin-list />
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "analysis",
  data: () => ({}),
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    fetchAnalysis() {
      this.loading = true;
      this.$axios
        .get("https://api.ubcoins.co/api/v3/analysis")
        .then((response) => {
          console.log(response.data);
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchAnalysis();
  },
};
</script>
<style>
.v-tab {
  min-width: 60px !important;
}

.theme--dark.v-tabs-items {
  background-color: transparent !important;
}

.full-screen-container {
  height: 90vh;
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
