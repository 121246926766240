<template>
    <v-row>
        <v-col cols="12" xs="12" sm="10" md="8" lg="5">
            <v-card class=" mt-5 pa-5" color="#272727" style="border-radius: 10px; ">
                <h5 class="mb-5"></h5>

                <div>
                    <div v-if="client.user.account_status == 'Unverified'">
                        <!-- <span style="font-size: 18px;font-weight: 700;line-height: 24px;">
                            KYC Verification
                        </span> -->

                        <main-account-verify></main-account-verify>
                    </div>

                    <div class="d-flex flex-column mt-2" v-else-if="client.user.account_status == 'Verified'">

                        <div class="d-flex flex-column align-center">

                            <h4>Enjoy Trading with UPBIT</h4>

                            <span style="font-size: 14px; font-weight: 300;">
                                Your Account is now Verified
                            </span>

                            <v-avatar class="mt-5 " size="250">
                                <img :width="300" src="../../../assets/images/verified-1.jpg" />
                            </v-avatar>

                        </div>


                        <v-container>
                            <div class="mt-2 d-flex align-center justify-space-between">
                                <span style="font-size: 16px; color: #9493ac;">Email: </span>

                                <span style="font-size: 16px; font-weight: 500;">
                                    {{ user.email }}
                                </span>
                            </div>

                            <div class="mt-2 d-flex align-center justify-space-between">
                                <span style="font-size: 16px; color: #9493ac;">Mobile Number: </span>

                                <span style="font-size: 16px; font-weight: 500;">
                                    {{ user.mobile_number }}
                                </span>
                            </div>

                            <div class="mt-2 d-flex align-center justify-space-between">
                                <span style="font-size: 16px; color: #9493ac;"> Unique ID: </span>

                                <span style="font-size: 16px; font-weight: 500;">
                                    {{ user.user_unique_id }}
                                </span>
                            </div>
                        </v-container>

                        <v-btn class="mt-5" block x-large light @click="$router.push('/profile')">
                            Proceed to profile
                        </v-btn>

                        <!-- <v-chip class=""> <v-icon color="success" size="15">mdi-check-circle-outline</v-icon>
                            {{ client.user.account_status }}
                        </v-chip> -->

                    </div>
                </div>

            </v-card>
        </v-col>
    </v-row>
</template>
<script>
export default {
    data: () => ({
        loading: false,
    }),

    computed: {
        client() {
            return this.$store.getters.client;
        },
        user() {
            return this.$store.getters.user;
        },
    },

};
</script>
