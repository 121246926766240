<template>
  <v-row justify="center">
    <v-col cols="12" xs="12" lg="5">
      <v-row>
        <v-col cols="12">

          <!-- all_time_high
                    :
                    "N/A"
                    all_time_low
                    :
                    "N/A"
                    coin_id
                    :
                    2
                    created_at
                    :
                    "2024-03-13T12:29:14.000000Z"
                    dilluted_market_cap
                    :
                    "N/A"
                    id
                    :
                    1
                    intro
                    :
                    "N/A"
                    issue_date
                    :
                    "N/A"
                    issue_price
                    :
                    "N/A"
                    market_cap
                    :
                    "N/A"
                    market_dominance
                    :
                    "N/A"
                    max_supply
                    :
                    "N/A"
                    total_supply
                    :
                    "N/A" -->

          <div v-if="isLoading">
            Loading...
          </div>

          <div v-else>
            <div class="mt-4 d-flex justify-space-between">
              <div style="font-size: 14px; color: #848e9c">
                Ranking
              </div>

              <div style="font-size: 14px;">
                No. 1
              </div>
            </div>

            <div class=" mt-4 d-flex justify-space-between">
              <div style="font-size: 14px; color: #848e9c">
                Market Capitalization
              </div>

              <div v-if="!tokenProfile.market_cap" style="font-size: 14px;">
                N/A
              </div>

              <div v-else style="font-size: 14px;">
                {{ tokenProfile.market_cap }}
              </div>
            </div>

            <div class="mt-4 d-flex justify-space-between">
              <div style="font-size: 14px; color: #848e9c">
                Market Dominance Index
              </div>

              <div v-if="!tokenProfile.market_dominance" style="font-size: 14px;">
                N/A
              </div>

              <div v-else style="font-size: 14px;">
                {{ tokenProfile.market_dominance }}
              </div>
            </div>

            <div class="mt-4 d-flex justify-space-between">
              <div style="font-size: 14px; color: #848e9c">
                Circulating Supply
              </div>

              <div v-if="!tokenProfile.total_supply" style="font-size: 14px;">
                N/A
              </div>

              <div v-else style="font-size: 14px;">
                {{ tokenProfile.total_supply }}
              </div>
            </div>

            <div class="mt-4 d-flex justify-space-between">
              <div style="font-size: 14px; color: #848e9c">
                Maximum Supply
              </div>

              <div v-if="!tokenProfile.max_supply" style="font-size: 14px;">
                N/A
              </div>

              <div v-else style="font-size: 14px;">
                {{ tokenProfile.max_supply }}
              </div>

            </div>

            <div class="mt-4 d-flex justify-space-between">
              <div style="font-size: 14px; color: #848e9c">
                Total
              </div>


              <div v-if="!tokenProfile.max_supply" style="font-size: 14px;">
                N/A
              </div>

              <div v-else style="font-size: 14px;">
                {{ tokenProfile.max_supply }}
              </div>

            </div>

            <div class="mt-4 d-flex justify-space-between">
              <div style="font-size: 14px; color: #848e9c">
                Issue Date
              </div>

              <div v-if="!tokenProfile.issue_date" style="font-size: 14px;">
                N/A
              </div>

              <div v-else style="font-size: 14px;">
                {{ tokenProfile.issue_date }}
              </div>
            </div>

            <div class="mt-4 d-flex justify-space-between">
              <div style="font-size: 14px; color: #848e9c">
                Historical High
              </div>

              <div v-if="!tokenProfile.all_time_high" style="font-size: 14px;" align="end">
                N/A
              </div>

              <div v-else align="end" style="font-size: 14px;">
                {{ tokenProfile.all_time_high }}
              </div>
            </div>

            <div class="mt-4 d-flex justify-space-between">
              <div style="font-size: 14px; color: #848e9c">
                Historical Low
              </div>

              <div v-if="!tokenProfile.all_time_low" style="font-size: 14px;" align="end">
                N/A
              </div>

              <div v-else align="end" style="font-size: 14px;">
                {{ tokenProfile.all_time_low }}

              </div>
            </div>
          </div>

        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" xs="12" lg="5">
      <v-row>
        <v-col cols="12">
          <div class="mt-4">

            <div>
              <span style="font-size: 14px;">Intro</span>
            </div>

            <div>
              <div v-if="!tokenProfile.intro" style="font-size: 14px;">
                N/A
              </div>

              <div v-else>
                <p style="font-size: 12px; color: #848e9c;">
                  {{ tokenProfile.intro }}
                </p>
              </div>

            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
export default {
  name: "coinInfo",
  isLoading: false,
  props: {
    // coinProfile: {
    //     type: Object,
    //     required: true
    // }
  },
  data: () => ({
    tokenProfile: [],
  }),

  mounted() {
    // console.log('coinProfile:', this.coinProfile);
    this.coinProfile()
  },

  computed: {
    // formattedPrice() {
    //   return formatPrice(this.priceFromGMSWAP, 2);
    // },

    isMarketDisabled() {
      return this.toggleMarketType === 'market';
    },

    formattedPrice() {
      return (price) => numeral(price).format('0,0.00');
    },

    formattedChange() {
      return (volume) => numeral(volume).format('0,0.00')
    },

    formattedSymbol() {
      return (value) => numeral(value).format('0a')
    },

    delegateColor() {
      return this.delegateType == "SELL" ? "redPrimary" : "greenPrimary";
    },

    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    client() {
      return this.$store.getters.client;
    },
    delegateType: {
      get() {
        return this.$store.state.delegateType;
      },
      set(value) {
        this.$store.state.delegateType = value;
      },
    },
    selectedCoin: {
      get() {
        return this.$store.state.selectedCoin;
      },
      set(value) {
        this.$store.state.selectedCoin = value;
      },
    },
    selectedMarketCoin() {
      return this.$store.getters.marketCoins.find(
        (coin) => coin.name == this.selectedCoin
      );
    },
    marketCoins() {
      return this.$store.getters.marketCoins;
    },
    coins() {
      //Get name only
      return this.$store.getters.marketCoins.map((coin) => coin.name);
    },
    numberOfOrder() {
      if (this.priceType === "AMOUNT") {
        return this.amount / this.valuePerUnit;
      } else {
        return this.amount;
      }
    },
    total() {
      if (this.priceType === "AMOUNT") {
        return this.amount;
      } else {
        return this.amount * this.valuePerUnit;
      }
    },
    valuePerUnit() {
      return this.selectedMarketCoin.value;
    },
    sellBalanceInUSDT() {
      let wallets = this.$store.getters.wallet.wallet;
      return wallets.find((item) => item.name === this.selectedCoin).usdt_value;
    },
    sellBalanceInCoin() {
      let wallets = this.$store.getters.wallet.wallet;
      let balance = wallets.find(
        (item) => item.name === this.selectedCoin
      ).coin_value;

      return balance;
    },
    buyBalanceInUSDT() {
      let wallets = this.$store.getters.wallet.wallet;
      return wallets.find((item) => item.name === "USDT").usdt_value;
    },
    buyBalanceInCoin() {
      let wallets = this.$store.getters.wallet.wallet;
      let balance =
        wallets.find((item) => item.name === "USDT").usdt_value /
        this.valuePerUnit;
      return balance;
    },
    balanceToDisplay() {

      return this.priceType == "TOTAL"
        ? this.buyBalanceInCoin
        : this.buyBalanceInUSDT
    },
    sellBalanceToDisplay() {
      return this.priceType == "TOTAL"
        ? this.sellBalanceInCoin
        : this.sellBalanceInUSDT;
      // return 0;
    },
    minimumSell() {
      if (this.minimum.minimum_sell == "0") return 0;
      else {
        if (this.priceType == "TOTAL") return this.minimum.minimum_sell;
        else return this.minimum.minimum_sell * this.valuePerUnit;
      }
    },
    minimumBuy() {
      if (this.minimum.account_balance_limit_spot == "0") return 0;
      else {
        if (this.priceType == "TOTAL")
          return this.minimum.account_balance_limit_spot;
        else return this.minimum.account_balance_limit_spot * this.valuePerUnit;
      }
    },
    orders: function () {
      return this.$store.state.orders;
    },
  },

  methods: {
    coinProfile() {
      this.isLoading = true
      axios.get('https://api.ubcoins.co/api/v3/coin-profile', {
        params: {
          name: this.selectedCoin
        }
      }).then(response => {
        try {
          this.tokenProfile = response.data.coin_profile
        } catch (error) {
          console.log(error)
        } finally {
          this.isLoading = false
        }

      })
    }
  }

}

</script>