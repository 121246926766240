<template>
  <v-row justify="center">
    <v-col xs="12" sm="10">
      <v-container>
        <v-row>

          <v-col cols="12" xs="12" lg="10" xl="10">
            <div class="d-flex align-center">
              <v-toolbar class="transparent elevation-0">
                <v-btn icon @click="$router.push('/wallet');">
                  <icon icon="material-symbols:arrow-back" width="24" height="24"></icon>
                </v-btn>
                <v-toolbar-title> Wallet</v-toolbar-title>
              </v-toolbar>
            </div>
          </v-col>

          <v-col cols="12" xs="12" sm="8" md="6" lg="5" class="mt-3">

            <h4>Deposit Funds</h4>

            <div v-if="!$vuetify.breakpoint.xs">
              <v-select :items="coins" :menu-props="{ bottom: true, offsetY: true }" v-model="selectCoin" class="mt-3"
                placeholder="Select coin" outlined dense @input="selectedCoin()" item-text="name" item-value="value">
                <template v-slot:item="{ item }">
                  <div class="pa-2">
                    <v-avatar size="30">
                      <v-img :src="item.image" />
                    </v-avatar>
                    {{ item.name }}
                  </div>
                </template>
              </v-select>
            </div>

            <!-- mobile mode -->
            <div v-if="$vuetify.breakpoint.xs">

              <div class="text-center my-5 ">
                <VueQrcode v-if="address" :value="address" :options="{ width: 150 }"
                  style="padding: 10px; border-radius: 10px; border: 1px solid #b7bdc6">
                </VueQrcode>

                <!-- <div v-if="!address">
              <h6>No Data Available</h6>
            </div> -->
              </div>

              <div>
                <v-select :items="coins" :menu-props="{ bottom: true, offsetY: true }" v-model="selectCoin" class="mt-3"
                  placeholder="Select coin" outlined dense @input="selectedCoin()" item-text="name" item-value="value">
                  <template v-slot:item="{ item }">
                    <div class="pa-2">
                      <v-avatar size="30">
                        <v-img :src="item.image" />
                      </v-avatar>
                      {{ item.name }}
                    </div>
                  </template>
                </v-select>
              </div>

              <v-sheet class="pa-5" color="#00000000" style="border-radius: 5px; border: 1px solid #2b3139;">

                <div v-if="selectedType === '' || selectCoin === ''" class="d-flex align-center justify-space-between">

                  <!-- Default -->
                  <div class="d-flex flex-column">
                    <span style="font-size: 12px;">Network</span>
                    <span style="font-size: 1rem; color: #b7bdc6 ;">Please Choose network first</span>
                  </div>

                  <div>
                    <v-btn :disabled="!selectCoin" icon @click="openMobileDiaLog">
                      <v-icon>mdi-swap-horizontal</v-icon>
                    </v-btn>
                  </div>
                </div>

                <div v-else class="d-flex flex-column justify-space-between">

                  <div class="d-flex align-center justify-space-between">

                    <div v-if="selectedType === 'TRC20'" class="d-flex flex-column">
                      <span style="font-size: 12px;">Network</span>
                      <span style="font-size: 1rem; color: #b7bdc6 ;">TRX Tron(TRC20)</span>
                    </div>


                    <div v-if="selectedType === 'ERC20'" class="d-flex flex-column">
                      <span style="font-size: 12px;">Network</span>
                      <span style="font-size: 1rem; color: #b7bdc6 ;">Ethereum (ERC20)</span>
                    </div>

                    <div v-if="selectedType === 'BEP20'" class="d-flex flex-column">
                      <span style="font-size: 12px;">Network</span>
                      <span style="font-size: 1rem; color: #b7bdc6 ;">BNB Smart Chain (BEP20)</span>
                    </div>

                    <div>
                      <v-btn icon @click="openMobileDiaLog">
                        <v-icon>mdi-swap-horizontal</v-icon>
                      </v-btn>
                    </div>

                  </div>

                  <v-divider></v-divider>

                  <div class="d-flex flex-column">

                    <span style="font-size: 12px;">Deposit Address</span>

                    <div v-if="!address" class="d-flex flex-column">
                      <span style="font-size: 14px; margin-bottom: 10px"> Request for an address</span>
                      <v-btn color="primary" style="color:#1C1D28" small dense>Generate Address</v-btn>
                    </div>

                    <div v-else class="d-flex">
                      <span style="font-size: 14px; margin-bottom: 10px">
                        {{ address }}
                        <v-btn small icon @click="handleCopyAddress(address)">
                          <v-icon style="font-size: 14px">mdi-content-copy</v-icon>
                        </v-btn>
                      </span>
                    </div>

                  </div>
                </div>

              </v-sheet>

              <div v-if="selectedType">
                <div class=" mt-3 d-flex align-center justify-space-between">
                  <label>Minimum Deposit</label>
                  <label>0.01 {{ selectCoin }}</label>
                </div>

                <div class="mt-2">
                  <span v-if="isDetailsCollapsed === false" style="color: grey">
                    More Details
                    <v-btn @click="isDetailsCollapsed = true" icon>
                      <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
                    </v-btn>
                  </span>

                  <span v-else>
                    Less
                    <v-btn @click="isDetailsCollapsed = false" icon>
                      <v-icon>mdi-arrow-up-drop-circle-outline</v-icon>
                    </v-btn>
                  </span>
                </div>

                <div outlined color="primary" v-if="isDetailsCollapsed === true"
                  class="mt-2 d-flex justify-space-between">
                  <div>
                    <span class="item-subtitle ">Deposit to</span><br />
                    <span class="item-subtitle ">Minimum deposit</span><br />
                    <span class="item-subtitle ">Credited (Trading enabled)</span><br />
                    <span class="item-subtitle ">Unlocked (Withdrawal enabled)</span><br />
                    <span class="item-subtitle ">* Do not transact with Sanctioned Entities</span><br />
                    <span class="item-subtitle ">* Don't send NFTs to this address</span><br />
                  </div>

                  <div>
                    <span style="color: #f7a600; font-size: 13px">Spot Wallet</span><br />
                    <span style="color: #ffff; font-size: 13px">>{{ minimumAmount }} {{ selectCoin }}</span><br />
                    <span style="color: #ffff; font-size: 13px">{{ block }} Confirmation</span><br />
                    <span style="color: #ffff; font-size: 13px">{{ block2 }} Confirmation</span><br />
                  </div>
                </div>

              </div>

            </div>

            <DialogComponent :bindData="mobileDepositDialog" @update:bindData="updateDialogState"
              dialogTitle="Choose Network ">
              <template v-slot:default>

                <v-sheet v-model="selectedType"
                  @click="handleSelectWalletType(name, subname, arrival, confirmation, value)"
                  v-for=" { name, subname, arrival, confirmation, value } in walletTypes" class="mt-5 pa-5"
                  color="#00000000" style="border-radius: 5px; border: 1px solid #2b3139;">

                  <div class="d-flex flex-column">

                    <div>
                      <span>{{ name }}</span>
                      <span class="ml-1">{{ subname }}</span>
                    </div>

                    <span>{{ arrival }}</span>
                    <span>{{ confirmation }} confirmation/s</span>
                  </div>
                </v-sheet>

              </template>
            </DialogComponent>

            <div v-if="!$vuetify.breakpoint.xs">
              <v-select :items="walletTypes" :menu-props="{ bottom: true, offsetY: true }" class="mt-3"
                v-model="selectedType" outlined dense placeholder="Select Network" @input="selectType()" item-text="name"
                item-value="value">
                <template v-slot:selection="{ item, index }">
                  {{ item.name }}
                  <span style="font-weight: 300; margin-left: 5px; color: grey">
                    {{ item.subname }}</span>
                  <!-- {{ item.name }} -->
                </template>

                <template v-slot:item="{ item }">
                  <v-row style="padding: 5px" class="pa-3">
                    <v-col cols="6">
                      {{ item.name }} <br />
                      {{ item.subname }}
                    </v-col>
                    <v-col cols="6" class="text-end">
                      {{ item.arrival }} <br />
                      {{ item.confirmation }} confirmation/s

                      <!-- subname:'Tron(TRC20)', value: 'TRC20', arrival: '=2mins', confirmation:'1' -->
                    </v-col>
                  </v-row>
                </template>
              </v-select>
            </div>

            <div v-if="!$vuetify.breakpoint.xs" class="">

              <div v-if="selectedType == '' || selectCoin == ''">
                No Network Selected
              </div>

              <div v-else>
                <v-card class="pa-3" style="border: solid 1px; border-color: #2b3139; background-color: #00000000; ">

                  <div class="d-flex flex-column ">

                    <div v-if="!loading">
                      <VueQrcode v-if="address" :value="address" :options="{ width: 150 }" style="padding: 10px">
                      </VueQrcode>
                      <h6 v-else>No Data Available</h6>
                    </div>

                    <div class="d-flex flex-column ">
                      <h6 class="mr-3" style="color: grey">Address</h6>

                      <span v-if="address == ''" style="font-size: 14px; margin-bottom: 10px">Request for an address
                      </span>

                      <div v-if="!address" class="d-flex flex-column">
                        <span style="font-size: 14px; margin-bottom: 10px"> Request for an address</span>
                        <v-btn color="primary" style="color:#1C1D28;" dense>Generate Address</v-btn>
                      </div>

                      <span v-else style="font-size: 14px; margin-bottom: 10px">
                        {{ address }}
                        <v-icon style="color: grey; font-size: 12px">mdi-content-copy</v-icon>
                      </span>
                    </div>

                  </div>
                </v-card>

                <div class="mt-3 d-flex align-center justify-space-between">
                  <label>Minimum Deposit</label>
                  <label>{{ minimumAmount }} {{ selectCoin }}</label>
                </div>

                <div class="mt-2">
                  <span v-if="isDetailsCollapsed === false" style="color: grey">
                    More Details
                    <v-btn @click="isDetailsCollapsed = true" icon>
                      <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
                    </v-btn>
                  </span>

                  <span v-else>
                    Less
                    <v-btn @click="isDetailsCollapsed = false" icon>
                      <v-icon>mdi-arrow-up-drop-circle-outline</v-icon>
                    </v-btn>
                  </span>
                </div>

                <div outlined color="primary" v-if="isDetailsCollapsed === true"
                  class="mt-2 d-flex justify-space-between">
                  <div>
                    <span class="item-subtitle ">Deposit to</span><br />
                    <span class="item-subtitle ">Minimum deposit</span><br />
                    <span class="item-subtitle ">Credited (Trading enabled)</span><br />
                    <span class="item-subtitle ">Unlocked (Withdrawal enabled)</span><br />
                    <span class="item-subtitle ">* Do not transact with Sanctioned Entities</span><br />
                    <span class="item-subtitle ">* Don't send NFTs to this address</span><br />
                  </div>

                  <div>
                    <span style="color: #f7a600; font-size: 13px">Spot Wallet</span><br />
                    <span style="color: #ffff; font-size: 13px">>{{ minimumAmount }} {{ selectCoin }}</span><br />
                    <span style="color: #ffff; font-size: 13px">{{ block }} Confirmation</span><br />
                    <span style="color: #ffff; font-size: 13px">{{ block2 }} Confirmation</span><br />
                  </div>
                </div>

              </div>
            </div>

            <v-divider></v-divider>
          </v-col>

          <v-col v-if="!$vuetify.breakpoint.xs" cols="12" class="mt-3">

            <h4>Recent Deposits</h4>
            <!-- <span to="/wallet/history">More <v-icon>mdi-arrow-right-drop-circle</v-icon></span> -->

            <v-card class="" color="#272727" style="border-radius: 10px;">
              <v-data-table :loading="isLoading" :headers="headers" :items="depositHistory" :items-per-page="10"
                @click:row="rowClicked($event)" class="transparent px-5">

                <template v-slot:item.coin="{ item }">
                  <div v-if="item.coin == 'USDT'">
                    <div class="d-flex align-center">
                      <div>
                        <v-avatar size="30">
                          <v-img src="https://cryptologos.cc/logos/tether-usdt-logo.png"></v-img>
                        </v-avatar>
                        <span class="ml-3" style="margin-left: 5px">USDT</span><br />
                      </div>
                    </div>
                  </div>

                  <div v-if="item.coin == 'BTC'">
                    <div class="d-flex align-center">
                      <div>
                        <v-avatar size="30">
                          <v-img src="https://cryptologos.cc/logos/bitcoin-btc-logo.png"></v-img>
                        </v-avatar>
                        <span class="ml-3" style="margin-left: 5px">BTC</span><br />
                      </div>
                    </div>
                  </div>

                  <div v-if="item.coin == 'ETH'">
                    <div class="d-flex align-center">
                      <div>
                        <v-avatar size="30">
                          <v-img src="https://brandlogos.net/wp-content/uploads/2021/11/ethereum-logo.png"></v-img>
                        </v-avatar>
                        <span class="ml-3" style="margin-left: 5px">ETH</span><br />
                      </div>
                    </div>
                  </div>
                </template>

                <template v-slot:item.recharge_amount="{ item }">
                  {{ formattedPrice(item.recharge_amount) }}
                </template>

                <template v-slot:item.date="{ item }">
                  {{ formatDate(item.date) }}
                </template>

                <template v-slot:item.recharge_status="{ item }">
                  <v-chip small color="green" text-color="white">
                    {{ item.recharge_status }}
                  </v-chip>
                </template>

              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-col>


    <v-dialog v-model="depositHistoryDialog" max-width="500px">
      <v-card class="elevation-0" style="padding: 20px">
        <v-row>
          <v-col cols="12">
            <h4>Deposit Details</h4>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="6">
                <span> Status </span><br />
                <span> Date </span><br />
                <span> Coin </span><br />
                <span> Deposit amount </span><br />
                <span> Deposit wallet </span><br />
              </v-col>
              <v-col cols="6" class="text-end">
                <span>
                  {{ details.recharge_status }} </span><br />
                <span>
                  {{ details.date }} </span><br />
                <span>
                  {{ details.coin }} </span><br />
                <span>
                  {{ details.recharge_amount }} </span><br />
                <span> Spot Wallet </span><br />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="walletAddressDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-card-title style="display: flex; align-items: center; justify-content: center">
          <center>Choose Network</center>
        </v-card-title>
        <v-card-text>
          <v-card v-if="selectCoin == 'USDT' || selectCoin == 'USDC'" @click="selectType('TRC20')" style="
          margin-bottom: 3px;
          padding: 10px;
          line-height: 13px;
          border: solid 1px;
          border-color: #2b3139;
        " class="elevation-0">
            <h6 style="font-size: 15px">Tron(TRC20)</h6>
            <span class="mb-0" style="font-size: 11px; color: grey; line-height: 0">1 block confirmation</span><br />
            <span class="mb-0" style="font-size: 11px; color: grey; line-height: 0">Est. arrival = 2mins</span>
          </v-card>

          <v-card v-if="selectCoin == 'ETH' ||
            selectCoin == 'USDT' ||
            selectCoin == 'USDC'" @click="selectType('ERC20')" style=" margin-bottom: 3px;padding: 10px;line-height: 13px; border: solid 1px; border-color: #29313e;
              " class="elevation-0">
            <h6 style="font-size: 15px">Ethereum(ERC20)</h6>
            <span class="mb-0" style="font-size: 11px; color: grey; line-height: 0">6 block confirmation</span><br />
            <span class="mb-0" style="font-size: 11px; color: grey; line-height: 0">Est. arrival = 4mins</span>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { Notify } from "notiflix/build/notiflix-notify-aio";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import moment from "moment";
import numeral from "numeral";

import DialogComponent from "@/components/ui/DialogComponent.vue";
import { generateAddress } from "@/services/walletServices";

export default {
  components: {
    VueQrcode,
    DialogComponent,
  },
  data: () => ({
    isLoading: false,
    isDetailsCollapsed: false,
    mobileDepositDialog: false,
    walletValue: null,
    messages: [],
    activeStep: 1,
    e6: 1,
    more: 0,
    headers: [
      {
        value: "coin",
        text: "Asset",
        align: "start",
        sortable: true,
      },

      {
        value: "recharge_amount",
        text: "Amount",
        sortable: true,
        align: "start",
      },

      {
        value: "date",
        text: "Date",
        align: "start",
        sortable: true,
      },
      {
        value: "recharge_status",
        text: "status",
        sortable: true,
        align: "center",
      },
    ],
    loading: false,
    depositHistoryDialog: false,
    walletAddressDialog: false,
    walletCoinDialog: false,
    depositHistory: [],
    block: 1,
    block2: 1,
    minimumAmount: 0.01,
    details: [],
    selectCoin: "",
    walletTypes: [
      {
        name: "TRX",
        subname: "Tron(TRC20)",
        value: "TRC20",
        arrival: "=2mins",
        confirmation: "1",
      },
      {
        name: "ETH",
        value: "ERC20",
        subname: "ETHEREUM(ERC20)",
        arrival: "=5mins",
        confirmation: "6",
      },
      {
        name: "BNB",
        value: "BEP20",
        subname: "BNB(BEP20)",
        arrival: "=5mins",
        confirmation: "6",
      },

    ],

    coins: [
      {
        name: "USDT",
        value: "USDT",
        image:
          "https://seeklogo.com/images/T/tether-usdt-logo-FA55C7F397-seeklogo.com.png",
        minimum: 1,
      },

      {
        name: "USDC",
        value: "USDC",
        image:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuYJnm4Chhybupz43VtY-t-7VaY2WvYDuSwg&s",
        minimum: 1,
      },
      {
        name: "ETH",
        value: "ETH",
        image:
          "https://brandlogos.net/wp-content/uploads/2021/11/ethereum-logo.png",
        minimum: 0.0013,
      },
      {
        name: "BTC",
        value: "BTC",
        image:
          "https://brandlogos.net/wp-content/uploads/2021/11/bitcoin-logo-768x768.png",
        minimum: 0.0013,
      },
    ],
    selectedType: "",
    address: null,
  }),

  computed: {

    computedDialogConent() {
      return <h1>test</h1>
    },

    formatDate() {
      return function (date) {
        return moment(date).format('YYYY-MM-DD'); // Format the date using Moment.js
      };
    },

    formattedPrice() {
      return (price) => numeral(price).format('$0,0.00'); // Format price as currency
    },
  },

  methods: {
    generateWalletAddress() {
      this.$axios.get('https://api.ubcoins.co/api/v3/generate-wallet', {
        params: {
          wallet_type: this.selectedType
        }
      }).then(response => {
        this.fetchAddress()
      })
    },
    handleCopyAddress(address) {
      navigator.clipboard.writeText(this.address)
        .then(() => {
          // Success message
          this.$swal({
            title: "Copied!",
            text: "Address copied to clipboard.",
            icon: "success",
            timer: 2000,
            buttons: false,
          });
        })
        .catch((error) => {
          // Error message
          console.error('Unable to copy:', error);
          this.$swal({
            title: "Error",
            text: "Unable to copy address to clipboard.",
            icon: "error",
            timer: 2000,
            buttons: false,
          });
        });
    },

    handleSelectWalletType(name, subname, arrival, confirmation, value) {
      this.mobileDepositDialog = false
      this.selectedType = value
      console.log(value)
      this.selectType()
    },

    rowClicked(value) {
      this.details = value;
      this.depositHistoryDialog = true;

      // console.log(value)
    },

    openMobileDiaLog() {
      this.mobileDepositDialog = true
    },

    updateDialogState(value) {
      this.mobileDepositDialog = value;
    },

    fetchHistories() {
      this.loading = true;
      this.isLoading = true
      this.$axios
        .get("https://api.ubcoins.co/api/v3/deposit-history")
        .then((response) => {
          try {
            this.depositHistory = response.data.deposit;
            this.loading = false;
          } catch (error) {
            console.log(error)
          } finally {
            this.isLoading = false
          }
        });
    },
    selectedCoin() {
      if (this.selectCoin == "USDT" || this.selectCoin == 'USDC') {
        (this.walletTypes = [
          {
            name: "TRX",
            subname: "Tron(TRC20)",
            value: "TRC20",
            arrival: "=2mins",
            confirmation: "1",
          },
          {
            name: "ETH",
            value: "ERC20",
            subname: "ETHEREUM(ERC20)",
            arrival: "=5mins",
            confirmation: "6",
          },
          {
            name: "BNB Smart Chain",
            value: "BEP20",
            subname: "(BEP20)",
            arrival: "=4mins",
            confirmation: "6",
          },

          // {
          //   name: "BTC",
          //   value: "BTC",
          //   subname: "BITCOIN(BTC)",
          //   arrival: "=5mins",
          //   confirmation: "6",
          // },
        ]),
          console.log(this.selectCoin);
        (this.minimumAmount = "0.01");
        // this.e6 = 2
      } else if (this.selectCoin == "ETH" || this.selectCoin == "USDC") {
        (this.walletTypes = [
          // { name: "TRC20", minimum: 'TRC20' },
          {
            name: "ETH",
            value: "ERC20",
            subname: "ETHEREUM(ERC20)",
            arrival: "=5mins",
            confirmation: "6",
          },
          // { name: "OMNI", minimum: 'OMNI' },
        ]),
          console.log(this.selectCoin);
        this.minimumAmount = "0.000000001";
        // this.e6 = 2
      }
      else if (this.selectCoin == 'BTC') {
        (this.walletTypes = [
          {
            name: "BTC",
            value: "BTC",
            subname: "BITCOIN(BTC)",
            arrival: "=5mins",
            confirmation: "6",
          },
        ])

        this.minimumAmount = 0.0010
      }
      // this.walletCoinDialog = false
      // this.walletAddressDialog = true
    },
    selectType() {
      if (this.selectedType === "TRC20") {
        this.block = "1";
      } else if (this.selectedType === "ERC20") {
        this.block = "6";
        this.block2 = "64";
      } else if (this.selectedType === "BEP20") {
        this.block = "15"
        this.block2 = "15";
      }
      this.fetchAddress();
      this.walletAddressDialog = false;
    },
    goBack() {
      this.$router.go(-1);
    },
    async fetchAddress() {
      try {
        this.loading = true;
        const response = await this.$axios.get(
          "https://api.ubcoins.co/api/v3/fetch/address",
          {
            params: {
              wallet_type: this.selectedType,
            },
          }
        );
        if (response.data.wallet)
          this.address = response.data.wallet.wallet_address;
        else this.address = null;
      } catch (error) {
        console.log(error);
        return [];
      } finally {
        this.loading = false;
      }
    },
    copyAddress() {
      navigator.clipboard.writeText(this.address);
      Notify.success("Copied to clipboard");
      //   this.$swal({
      //     toast: true,
      //     position: "top-end",
      //     icon: "success",
      //     title: "Copied to clipboard",
      //     showConfirmButton: false,
      //     timer: 3000, // milliseconds
      //   });
    },
  },
  mounted() {
    // this.connectSocket()
    this.fetchAddress();
    this.fetchHistories();
  },
};
</script>

<style>
.item-subtitle {
  color: gray;
  font-size: 13px;
}

/* Position the logout button at the bottom right corner */
.v-btn--absolute {
  position: fixed;
  bottom: 16px;
  right: 16px;
}

/* Button Spinner */
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>

<!-- <template v-slot:selection="{ item, index }">
    <v-avatar size="30">
      <v-img  :src="item.image"/>
    </v-avatar>
    {{ item.name }}
  
  {{ item.name }}
</template> -->

  <!-- <v-chip>
    <v-avatar size="3">
      <v-img src="https://seeklogo.com/images/T/tether-usdt-logo-FA55C7F397-seeklogo.com.png"></v-img>
    </v-avatar><span style="margin-left:5px">USDT</span>
  </v-chip>

  <v-chip class="ml-1">
      <v-avatar size="3">
        <v-img src="https://brandlogos.net/wp-content/uploads/2021/11/ethereum-logo.png"></v-img>
      </v-avatar><span style="margin-left:5px">ETHEREUM</span>
  </v-chip>

  <v-chip class="ml-1">
        <v-avatar size="3">
          <v-img src="https://cryptologos.cc/logos/usd-coin-usdc-logo.png"></v-img>
        </v-avatar><span style="margin-left:5px">USDC</span>
  </v-chip> -->