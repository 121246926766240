<template>
	<div class="TVChartContainer" ref="chartContainer" />
</template>

<script>
// import { widget } from '../../../public/charting_library';
import { widget } from '../../public/charting_library'
// import { UDFCompatibleDatafeed } from '../../public/datafeeds/udf/src/udf-compatible-datafeed';
import { UDFCompatibleDatafeed } from '../../public/datafeeds/udf/lib/udf-compatible-datafeed'

function getLanguageFromURL() {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(window.location.search);
	return results === null
		? null
		: decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export default {
	name: 'TVChartContainer',
	props: {
		symbol: {
			default: 'BTC',
			type: String,
		},
		interval: {
			default: '1h',
			type: String,
		},
		datafeedUrl: {
			// default: 'https://demo_feed.tradingview.com',
			// default: 'http://localhost:8000/api',
			default: 'https://api.ubcoins.co/api/v3',
			// supported_resolutions: {
			// 			1: "1m", // 1 minute
			// 			5: "5m",
			// 			15: "15m",
			// 			60: "1h",
			// 			D: "1d",
			// 			// Add more mappings as needed
			// 			},
			type: String,
		},
		libraryPath: {
			default: '/charting_library/',
			type: String,
		},
		chartsStorageUrl: {
			// default: 'https://saveload.tradingview.com',
			type: String,
	},
		chartsStorageApiVersion: {
			default: '1.1',
			type: String,
		},
		clientId: {
			default: 'api.ubcoins.co',
			type: String,
		},
		userId: {
			default: 'public_user_id',
			type: String,
		},
		fullscreen: {
			default: false,
			type: Boolean,
		},
		// autosize: {
		// 	default: true,
		// 	type: Boolean,
		// },
		studiesOverrides: {
			type: Object,
		},
	},
	tvWidget: null,
	mounted() {
		console.log('1hr')
		const container = this.$refs.chartContainer;
		const widgetOptions = {
			symbol: this.marketCoin,
			// BEWARE: no trailing slash is expected in feed URL
			datafeed: new UDFCompatibleDatafeed(this.datafeedUrl),
			interval: this.interval,
			container: container,
			library_path: this.libraryPath,
			locale: getLanguageFromURL() || 'en',
			disabled_features: ['use_localstorage_for_settings'],
			enabled_features: ['study_templates'],
			charts_storage_url: this.chartsStorageUrl,
			charts_storage_api_version: this.chartsStorageApiVersion,
			client_id: this.clientId,
			user_id: this.userId,
			fullscreen: this.fullscreen,
			autosize: true,
			studies_overrides: this.studiesOverrides,
			theme: "dark",
			overrides: {
				"paneProperties.background": '#1f2630',
				"paneProperties.backgroundType": "solid"
			},
			// backgroundColor: "blue",
			// toolbar: "hide",
			enabled_features: [
				"hide_left_toolbar_by_default",
				"chart_style_hilo_last_price",
				"hide_resolution_in_legend",
				"hide_unresolved_symbols_in_legend",
				"chart_style_hilo",
				"show_symbol_logos"
			],
			toolbar_bg: '#1f2630',
			disabled_features: [
				"items_favoriting", 
				// "legend_context_menu",
				// "hide_main_series_symbol_from_indicator_legend",
				// "symbol_info",
				"header_compare",
				"header_fullscreen_button",
				"header_settings",
				"header_quick_search",
				"symbol_search_hot_key",
				"show_hide_button_in_legend",
				"format_button_in_legend",
				"header_symbol_search",
				// "show_object_tree",
				"header_saveload", 
				"compare_symbol_search_spread_operators",
				// "legend_widget",
				"format_button_in_legend",
				"delete_button_in_legend",
				"show_hide_button_in_legend",
				"create_volume_indicator_by_default",
				"show_chart_property_page",
				// "control_bar",
				"always_show_legend_values_on_mobile",
				"adaptive_logo",
				// "header_widget",
				// "main_series_scale_menu",
				"header_resolutions",
				"timeframes_toolbar"
			],

			// supported_resolutions: ["1", "5", "15", "60", "D"],
		};

		const tvWidget = new widget(widgetOptions);
		this.tvWidget = tvWidget;
		// tvWidget.getStudyInputs('MACD');

		// tvWidget.activeChart().createStudy('MACD', false, false, { in_0: 14, in_1: 30, in_3: 'close', in_2: 9 });
		// tvWidget.activeChart().createStudy('Moving Average Exponential', false, false, { length: 26 });
		// tvWidget.activeChart().createStudy('Stochastic', false, false, { in_0: 26 }, { "%d.color": "#FF0000" });
		// tvWidget.activeChart().createStudy('Price Channel', true, false, { in_0: 26 }, null, { checkLimit: false, priceScale: 'new-left' });

		tvWidget.onChartReady(() => {
			tvWidget.headerReady().then(() => {
				const button = tvWidget.createButton();

				button.setAttribute('title', 'Click to show a notification popup');
				button.classList.add('apply-common-tooltip');

				button.addEventListener('click', () =>
					tvWidget.showNoticeDialog({
						title: 'Notification',
						body: 'TradingView Charting Library API works correctly',
						callback: () => {
							// eslint-disable-next-line no-console
							// console.log('Noticed!');
						},
					})
				);
				button.innerHTML = 'Check API';
			});
		});

		// console.log(this.marketCoin)
	},
	destroyed() {
		if (this.tvWidget !== null) {
			this.tvWidget.remove();
			this.tvWidget = null;
		}
	},
	computed:
	{
		marketCoin() {
			return this.$route.params.coin;
		},
	}
};
</script>

<style>
.TVChartContainer {
	width: 100%;
	max-height: auto;
	/* overflow: hidden; */
	height: 60vh;
	/* Ensure the chart doesn't overflow */
}
</style>
