<template>
   <v-row>
      <v-col v-if="!$vuetify.breakpoint.xs" class="mt-5" cols="12">
         <v-data-table :loading="isLoading" :headers="headers" :items="openOrders" :items-per-page="5"
            class="transparent elevation-1 pa-5">

            <template v-slot:item.delegate_type="{ item }">
               <v-chip small v-if="item.delegate_type == 'BUY'" color="#0ECB81">{{ $t('trade.buy') }}</v-chip>
               <v-chip small v-if="item.delegate_type == 'SELL'" color="#CA3F64">{{ $t('trade.sell') }}</v-chip>
            </template>

            <template v-slot:item.state="{ item }">
               <v-chip small v-if="item.state == 'PENDING'" outlined> {{ $t('trade.pending') }} </v-chip>
               <v-chip small v-if="item.state == 'APPROVED'" color="#0ECB81"> {{ $t('trade.filled') }}</v-chip>
               <v-chip small v-if="item.state == 'DISSAPROVED'" color="#CA3F64">{{ $t('trade.cancelled') }}</v-chip>
            </template>

         </v-data-table>
      </v-col>

      <v-col v-else class="mt-5" cols="12">

         <!-- Loading State -->
         <div v-if="isLoading" class="text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
         </div>

         <div v-else>
            <div v-if="openOrders.length <= 0">
               <div class="d-flex align-center justify-center mx-5 pa-10" style="height: 100%">
                  <div class="d-flex flex-column align-center justify-center">
                     <v-icon x-large color="#b7bdc6">
                        mdi mdi-note-search-outline
                     </v-icon>
                     <span style="font-size: 12px; color: #b7bdc6"> {{ $t('trade.noRecords') }}</span>
                  </div>
               </div>
            </div>

            <div v-else>
               <v-row v-for="{ delegate_type, number_of_order, final_price, state } in openOrders">
                  <v-col cols="12">
                     <div class="d-flex justify-space-between">
                        <span>{{ $t('trade.price') }} </span>
                        <span v-if="delegate_type == 'BUY'">{{ Number(number_of_order) / Number(final_price)
                        }}</span>
                        <span v-if="delegate_type == 'SELL'">{{ Number(number_of_order) * Number(final_price)
                        }}</span>
                     </div>

                     <div class="d-flex justify-space-between">
                        <span>{{ $t('trade.total') }}</span>
                        <span>{{ Number(number_of_order) * Number(final_price) }}</span>
                     </div>

                     <div class="d-flex justify-space-between">

                        <span v-if="state == 'PENDING'" outlined>{{ $t('trade.pending') }}</span>
                        <span v-if="state == 'APPROVED'" style="color: #0ECB81">{{ $t('trade.filled') }}</span>
                        <span v-if="state == 'DISSAPROVED'" style="color: #CA3F64"> {{ $t('trade.cancelled') }}</span>

                        <v-chip small v-if="delegate_type == 'BUY'" color="#0ECB81">{{ $t('trade.buy') }}</v-chip>
                        <v-chip small v-if="delegate_type == 'SELL'" color="#CA3F64">{{ $t('trade.sell') }}</v-chip>
                     </div>

                  </v-col>
                  <v-divider color="#b7bdc6 "></v-divider>
               </v-row>

            </div>
         </div>

      </v-col>
   </v-row>
</template>

<script>

import { getOpenOrders } from '@/services/Trades';

export default {
   data() {
      return {
         isLoading: false,
         openOrders: [],
         orderHistories: [],
         tradeHistory: [],
         funds: [],
         headers: [
            {
               value: "delegate_type",
               text: "Type",
               align: "start",
            },

            {
               value: "number_of_order",
               text: "Order",
               align: "end",
            },

            {
               value: "final_price",
               text: "Price",
               align: "end",
            },

            {
               value: "state",
               text: "Status",
               align: "center",
            }
         ]
      }
   },

   methods: {
      async fetchOpenOrders() {
         const openOrders = await getOpenOrders();
         this.$axios.get('https://api.ubcoins.co/api/v3/trades/open-orders')
            .then(response => {
               try {
                  this.isLoading = true
                  this.openOrders = response.data.orders
               } catch (error) {
                  console.log(error)
               } finally {
                  this.isLoading = false
               }


            })
      },
   },

   mounted() {
      this.fetchOpenOrders()
   }
}

</script>
