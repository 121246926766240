<template>
  <footer style=" background: #181A20">
    <v-container>

      <v-row justify="center" :class="footerClass" class="mb-15">

        <v-col cols="12" lg="3" align="start">
          <h3 style="color: #fff; font-weight: 700; font-size: 24px; line-height: 24px; margin: 0;">Community</h3>
          <v-row class="mt-3">

            <!-- <v-col cols="2">
              <v-btn icon>
                <v-icon>
                  fa-brands fa-discord
                </v-icon>
              </v-btn>
            </v-col>

            <v-col cols="2">
              <v-btn icon>
                <v-icon>
                  fa-brands fa-telegram
                </v-icon>
              </v-btn>
            </v-col>

            <v-col cols="2">
              <v-btn icon>
                <v-icon>
                  fa-brands fa-x-twitter
                </v-icon>
              </v-btn>
            </v-col>


            <v-col cols="2">
              <v-btn icon>
                <v-icon>
                  mdi mdi-facebook
                </v-icon>
              </v-btn>
            </v-col>

            <v-col cols="2">
              <v-btn icon>
                <v-icon>
                  fa-brands fa-tiktok
                </v-icon>
              </v-btn>
            </v-col> -->

            <v-col cols="12">
              <p style="color: #fff;">
                <strong> UPBIT</strong>
                {{ $t('landing.footer') }}
              </p>
            </v-col>

          </v-row>
        </v-col>

        <v-col cols="12" lg="3" align="start">
          <h3 style="color: #fff; font-weight: 700; font-size: 24px; line-height: 24px; margin: 0;">Quicklinks</h3>

          <ul style="list-style: none; margin-top: 16px; padding-inline-start: 0; line-height: 2rem;">

            <li>
              <router-link style=" text-decoration: none;" to="/markets/prices">
                {{ $t('nav.market') }}
              </router-link>
            </li>

            <li>
              <router-link style=" text-decoration: none;" to="/trades/trade-chart/BTC">
                {{ $t('nav.trade') }}
              </router-link>
            </li>

            <li>
              <router-link style=" text-decoration: none;" to="/trades/futures/BTC">
                {{ $t('nav.future') }}
              </router-link>
            </li>

            <li>
              <router-link style=" text-decoration: none;" to="/wallet">
                {{ $t('nav.wallet') }}
              </router-link>
            </li>

          </ul>
        </v-col>

        <v-col cols="12" lg="3" align="start">
          <div class="d-flex flex-column">

            <h3 style=" color: #fff; font-weight: 700; font-size: 24px; line-height: 24px; margin: 0;">About Us</h3>

            <ul style="list-style: none; margin-top: 16px; padding-inline-start: 0; line-height: 2rem;">

              <li>
                <a style="text-decoration: none;" @click="navigateToAbout">
                  {{ $t('footer.about') }}
                </a>
              </li>

              <li>
                <a style="text-decoration: none;" @click="navigateToTerms">
                  {{ $t('footer.terms') }}
                </a>
              </li>

              <li>
                <a style="text-decoration: none;" @click="navigateToPrivacyPolicy">
                  {{ $t('footer.privacy') }}
                </a>
              </li>

            </ul>
          </div>
        </v-col>

        <v-col cols="12" lg="3" align="start">
          <div class="d-flex flex-column">

            <h3 style=" color: #fff; font-weight: 700; font-size: 24px; line-height: 24px; margin: 0;">Download</h3>

            <div class="d-flex align-center mt-5">

              <!-- 
              <img @click="downloadAndroid()" src='../../assets/images/play-store.png'
                style="width: 150px; height: auto;" />

              <img @click="downloadIos()" src='../../assets/images/app-store.png' style="width: 150px; height: auto;" /> -->

              <div class="d-flex flex-column align-center justify-center mr-5" @click="downloadAndroid()"
                style="cursor: pointer;">
                <v-icon>fa-brands fa-android</v-icon>
                <span>{{ $t('footer.android') }}</span>
              </div>

              <div class="d-flex flex-column align-center justify-center" @click="downloadIos()" style="cursor: pointer;">
                <v-icon>fa-brands fa-apple</v-icon>
                <span>{{ $t('footer.ios') }}</span>
              </div>

            </div>

          </div>
        </v-col>

      </v-row>

      <v-divider color="#9493AC" elevation="10"></v-divider>

      <v-col col="12" lg="12" align="center">
        <span style="color:#fff">UPBIT 2019</span>
      </v-col>

    </v-container>
  </footer>

  <!-- <v-footer class="footer fixed app ma-0 pa-0">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" lg="8"
          ><v-row>
            <v-col cols="12" sm="6">
              <ul class="list-unstyled">
                <li><a href="/about">About</a></li>
                <li>
                  <a href="https://direct.lc.chat/18298131/">Help Center</a>
                </li>
                <li><a href="/contactus">Contact Us</a></li>
              </ul>
            </v-col>
            <v-col cols="12" sm="6">
              <ul class="list-unstyled">
                <li><a href="/terms">Terms of Use</a></li>
                <li><a href="/privacy">Privacy and Cookie Policy</a></li>
                <li>
                  <span>Copyright &copy; 2017-2023 UPBIT</span>
                </li>
              </ul>
            </v-col>
          </v-row></v-col
        >
      </v-row>
    </v-container>
  </v-footer> -->
</template>

<script>

export default {

  computed: {
    footerClass() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm ? 'mt-10' : 'pa-15';
    }
  },

  methods: {
    downloadAndroid() {
      window.open('https://api.ubcoins.co/api/v3/download-apk', '_blank');
    },
    downloadIos() {
      window.open('https://api.ubcoins.co/api/v3/download-ios', '_blank');
    },
    navigateToAbout() {
      // Check if the current route is not already "/about" before navigating
      if (this.$route.path !== '/about') {
        // Navigate to the "/about" route programmatically
        this.$router.push('/about');
      }
    },

    navigateToTerms() {
      // Check if the current route is not already "/legal-center/terms" before navigating
      if (this.$route.path !== '/legal-center/terms') {
        this.$router.push('/legal-center/terms');
      }
    },

    navigateToPrivacyPolicy() {
      // Check if the current route is not already "/legal-center/privacy" before navigating
      if (this.$route.path !== '/legal-center/privacy') {
        // Navigate to the "/privacy-policy" route programmatically
        this.$router.push('/legal-center/privacy');
      }
    },

  }
}

</script>

<style>
a {
  color: rgb(209, 209, 209) !important;
}
</style>
