<template>
  <div>
    <v-row justify="center" align="center">
      <v-col xs="12" sm="12" lg="12">
        <v-row>
          <v-col cols="6" xs="12">
            <div class="" style="margin-top:50px">
              <v-img :src="require('../../../assets/images/phone.png')" max-width="70%"
                style="margin-top:50px; margin: 0 auto;" />
            </div>
          </v-col>
          <v-col cols="6" xs="12">
            <div class="text-center">
              <h1 style="color: #fff;" :style="{
                fontSize: !$vuetify.breakpoint.xs ? '55px' : ($vuetify.breakpoint.sm || $vuetify.breakpoint.xs) ? '32px' : '',
                margin: $vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'auto' : '',
                maxWidth: $vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? '500px' : '',
                textAlign: $vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'center' : ''
              }">
                {{ $t('landing.phrase_3') }}
              </h1>
              <span class="mt-5" style="color: #6e6e6e;">
                {{ $t('landing.subtitle_1') }}
              </span>
              <v-row style="margin-top:20px">
                <v-col cols="5" style="text-align:center;">
                  <v-card style="color:transparent;margin-bottom:20px" flat>
                    <v-card-text class="d-flex justify-center align-center">
                      <v-container>
                        <center>
                          <img v-if="download == 'android'" width="170em" style="border-radius:10px"
                            src="@/assets/images/qr.png" />
                            <img v-if="download == 'ios'" width="170em" style="border-radius:10px"
                            src="@/assets/images/qr-ios.png" />
                            <br />
                        </center>

                      </v-container>
                    </v-card-text>
                  </v-card>
                  <span style="font-size:0.8em;font-weight:300px; margin-bottom:20px">
                    <v-icon>mdi-download-outline</v-icon>
                    More Download Options
                  </span><br style="margin-bottom:15px" /><span @click="changeToAndroid()"
                    style="cursor: pointer;margin-right:20px">
                    <v-icon v-if="download == 'ios'" style="color:grey">fa-brands fa-android</v-icon>
                    <v-icon v-if="download == 'android'" style="color:white">fa-brands fa-android</v-icon>

                  </span>

                  <span @click="changeToIos()" style="cursor: pointer;">
                    <v-icon v-if="download == 'android'" style="color:grey">fa-brands fa-apple</v-icon>
                    <v-icon v-if="download == 'ios'" style="color:white">fa-brands fa-apple</v-icon>
                    <v-icon></v-icon>

                  </span>
                  <div style="margin-top:20px">

                  </div>

                </v-col>
                <v-col cols="6" class="d-flex justify-center align-center">
                  <span style="font-weight:300;font-size:1em;">


                    Scan to Download App <br /><span style="font-size:1.5em;font-weight:600">{{ dlLabel }}</span><br />
                    <br />
                  </span>
                  <span style="font-weight:300;font-size:1em"> <br /></span>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
  </div>
</template>
<script>

import VueQrcode from "@chenfengyuan/vue-qrcode";

export default {
  data: () => ({
    loading: true,
    download: 'android',
    dlLabel: 'Android',

  }),

  components: {
    VueQrcode,
  },

  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
  computed: {
    marketCoins() {
      return this.$store.getters.marketCoins;
    },
    filteredCoins() {
      return this.marketCoins.filter((coin) => coin.type === "MAIN");
    },
  },

  methods: {
    changeToAndroid() {
      this.download = 'android'
      this.dlLabel = 'Android'
    },

    changeToIos() {
      this.download = 'ios'
      this.dlLabel = 'Apple'
    }
  }
};
</script>
<style>
#phones {
  /* max-height: 50px; */
  /* max-width:100px;  */
  /* object-fit: contain; */
}

.theme--dark.v-tabs-items {
  background-color: transparent !important;
}
</style>
