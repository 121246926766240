import Vue from "vue";
import Vuetify from 'vuetify';
import "vuetify/dist/vuetify.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@mdi/font/css/materialdesignicons.css";
import { Icon } from "@iconify/vue2";
import "../assets/scss/vuetify-override.scss";
import zhHans from 'vuetify/lib/locale/zh-Hans';
// import theme from "./theme";

Vue.use(Vuetify);
Vue.component("icon", Icon);

const opts = {
    // icons: { iconfont: ['fa', 'mdi']},

    // lang: {
    //     locales: { zhHans },
    //     current: 'zhHans', // Default to Chinese (Simplified)
    //     t: (key, ...params) => Vue.prototype.$i18n.t(key, params), // Ensure proper context for translation function
    // },

    theme: {
        dark: true,
        themes: {
            dark: {
                primary: "#fe5e02",
                secondary: "#089827",
                accent: "#40626E",
                tertiary: "#1e1e1e",
                greyPrimary: "#2e2e2e",
                greenPrimary: "#10bd7d",
                redPrimary: "#f64649",
            },
        },
    },
};

export default new Vuetify(opts);
