<template>
    <v-row class="mt-2 mb-5">

        <v-col cols=12 xs="12" sm="12" lg="12">
            <h4>{{ $t('markets.innoHeader') }} </h4>
            <span style="color:#FAFBFC;">
                {{ $t('markets.innoSubtitle') }}
            </span>
        </v-col>

        <v-col v-if="$vuetify.breakpoint.xs" align="start" cols="12" xs="12" sm="10" md="8" lg="6">
            <SearchBarComponent :search.sync="search" placeholder="Search Coin" />
        </v-col>

        <v-col v-if="!$vuetify.breakpoint.xs" cols="12">
            <!-- <v-card class="pa-5" color="#272727" style="border-radius: 10px;;"> -->
            <v-data-table :isLoading="isLoading" :headers="headers" :items="filteredData" :items-per-page="30"
                @click:row="rowClicked($event)" class="transparent">

                <template v-slot:top>
                    <v-toolbar flat color="transparent">
                        <v-row>
                            <v-col cols="12" xs="12" md="6" lg="3">
                                <SearchBarComponent :search.sync="search" placeholder="Search Coin" />
                            </v-col>
                        </v-row>
                    </v-toolbar>
                </template>


                <template v-slot:item.name="{ item }">
                    <div class="d-flex align-center">
                        <div class="mr-2">
                            <v-avatar color="white" size="30">
                                <v-img :src="item.image"></v-img>
                            </v-avatar>
                        </div>

                        <div class="mr-2 d-flex align-center">
                            <!-- <span class="mr-2" style="font-size:16px;">{{ item.coin_pair_name }}</span><br /> -->
                            <span class="subtitle-table">{{ (item.coin_pair_name).replace('USDT', '') }}</span>
                            <!-- <span style="font-size: 14px;"> {{ item.date }}</span><br /> -->
                        </div>
                    </div>
                </template>

                <template v-slot:item.close="{ item }">
                    <span class="subtitle-table">{{ formattedPrice(item.close) }}</span><br />
                    <!-- <span style="font-size:10px;color:grey">{{ (item.coin_pair_name).replace('USDT', '')}}</span> -->
                    <!-- <span> {{ item.date }}</span><br/> -->
                </template>

                <template v-slot:item.increase_in_value="{ item }">

                    <span class="subtitle-table" v-if="item.increase_in_value > 0" style="color:#0ECB81">{{
                        parseFloat(item.increase_in_value).toFixed(4)
                    }}%</span>
                    <span class="subtitle-table" v-else-if="item.increase_in_value < 0" style="color:#F6465D">
                        {{ parseFloat(item.increase_in_value).toFixed(4) }}%
                    </span>

                    <span class="subtitle-table" v-else style="color:grey">
                        {{ parseFloat(item.increase_in_value).toFixed(4) }}%
                    </span>

                    <!-- 
                                <span class="subtitle-table" v-if="item.increase_in_value > 0" style="color:#0ECB81">
                                    + {{ formattedIncInVolume(item.increase_in_value) }}
                                </span>
                                <span class="subtitle-table" v-if="item.increase_in_value < 0" style="color:#F6465D">
                                    {{ formattedIncInVolume(item.increase_in_value) }}
                                </span> -->

                </template>

                <template v-slot:item.volume="{ item }">
                    <span class="subtitle-table">{{ formattedVolume(item.volume) }}</span><br />
                </template>
            </v-data-table>
            <!-- </v-card> -->
        </v-col>

        <!-- mobile view -->
        <v-col v-else cols="12">

            <div v-if="filteredData.length <= 0 && !isLoading">
                <div class="d-flex align-center justify-center mt-10" style="height: 100%">
                    <div class="d-flex flex-column align-center justify-center">
                        <v-icon x-large color="#b7bdc6">
                            mdi mdi-note-search-outline
                        </v-icon>
                        <span style="font-size: 12px; color: #b7bdc6">No Records Found</span>

                        <div class="mt-3">
                            <ButtonComponent style="color: #1C1D28 ;" color="primary" label="Subscribe Now" />
                        </div>

                    </div>
                </div>
            </div>

            <v-row v-if="isLoading">
                <div class="text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </div>
            </v-row>

            <v-row v-if="!isLoading" class="mt-3"
                v-for="  { image, coin_pair_name, close, increase_in_value, volume } in filteredData"
                @click="mobileRowClicked(coin_pair_name)" style="border-bottom: 1px solid #929292;">
                <v-col sm=" 6" align="start">
                    <div class="d-flex align-center">
                        <v-avatar color="white" size="30" class="mr-2">
                            <v-img :src="image"></v-img>
                        </v-avatar>
                        {{ coin_pair_name }}
                    </div>
                </v-col>

                <v-col sm="6" align="end">
                    <span>{{ formattedPrice(close) }}</span><br />
                    <small>
                        <span v-if="increase_in_value > 0" style="color:#0ECB81">{{
                            parseFloat(increase_in_value).toFixed(4)
                        }}%</span>
                        <span v-if="increase_in_value < 0" style="color:#F6465D">
                            {{ parseFloat(increase_in_value).toFixed(4) }}%
                        </span>
                    </small>
                </v-col>
            </v-row>


            <!-- <v-col sm="4" align="center">
                    <span>{{ parseFloat(volume).toFixed(2) }}</span><br/>
                </v-col> -->
        </v-col></v-row>
</template>


<script>

import numeral from 'numeral';
import SearchBarComponent from '@/components/ui/SearchBarComponent.vue';

import { fetchInnoPairs } from '@/services/marketServices';

export default {

    data: () => ({
        search: "",
        innoCoins: [],
        isLoading: false,
        headers: [
            {
                value: "name",
                text: "Name",
                align: "start",
                sortable: true,
            },
            {
                value: "close",
                text: "Price",
                sortable: true,
                align: "end",
            },
            {
                value: "increase_in_value",
                text: "24h Change",
                sortable: true,
                align: "end",
            },
        ],
    }),

    components: {
        SearchBarComponent,
    },

    computed: {

        filteredData() {
            if (!this.search) {
                return this.innoCoins;
            }
            const searchTerm = this.search.toLowerCase();
            return this.innoCoins.filter(item => item.coin_pair_name.toLowerCase().includes(searchTerm));
        },

        formattedPrice() {
            return (price) => numeral(price).format('$0,0.00'); // Format price as currency
        },

        formattedVolume() {
            return (volume) => numeral(volume).format('0,a'); // Format volume as whole number
        },

        formattedIncInVolume() {
            return (value) => numeral(value).format('00.00%');
        }

    },

    methods: {

        rowClicked(value) {
            const name = (value.coin_pair_name).replace('USDT', '')
            // console.log(name)
            this.$store.state.selectedCoin = name;
            this.$router.push(`/trades/trade-chart/${name}`);
        },

        mobileRowClicked(value) {
            const name = (value).replace('USDT', '')
            this.$store.state.selectedCoin = name;
            this.$router.push(`/trades/trade-chart/${name}`);
        },

        //fetch all Inno Pairs
        async fetchInnoPairsData() {
            this.isLoading = true;
            try {
                this.innoCoins = await fetchInnoPairs();
            } catch (error) {
                console.error('Failed to fetch data:', error);
            } finally {
                this.isLoading = false
            }
        },

    },

    mounted() {
        this.fetchInnoPairsData()
    }

}
</script>

<style scoped>
.subtitle-table {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px
}

.custom-border {
    border-radius: 20px;
    border: 1px solid #2b3139;
}
</style>