<template>
  <!-- <v-app style="background: linear-gradient(to bottom right, #1f2339, #413e30);"> -->
  <v-app :style="{ background: backgroundColor }">
    <v-main>
      <div class="d-flex flex-column" style="min-height: 100vh;">
        <div>
          <v-container fluid>
            <v-row>
              <!-- Conditionally render main-sidebar -->
              <v-col
                style="background-color: #2e3f49; display: flex;  display: flex; flex-direction: column;  min-height: 100vh;  height: auto; overflow-y: auto; /* Add scroll if content overflows */"
                v-if="shouldShowSidebar && $vuetify.breakpoint.lgAndUp" cols="12" sm="2">
                <main-sidebar />
              </v-col>

              <v-col :class="{ 'sm-10': shouldShowSidebar, 'sm-12': !shouldShowSidebar }">
                <router-view />
              </v-col>
            </v-row>
          </v-container>
        </div>

        <div
          v-if="$route.path === '/' || $route.path === '/about' || $route.path === '/account/referral' || $route.path === '/account/security' || $route.path === '/markets/trading_data' || $route.path === '/legal-center/privacy' || $route.path === '/legal-center/terms' || $route.path === '/verification/overview' || $route.path === '/markets/prices' || $route.path === '/markets/pos' || $route.path === '/markets/pow' || $route.path === '/markets/innovation' || $route.path === '/markets/favorites' || $route.path === '/wallet/simple_earn' || $route.path === '/tier-verification'">
          <main-footer />
        </div>

      </div>
      <LiveChat />
    </v-main>
  </v-app>
</template>

<script>

import LiveChat from './components/LiveChat.vue';
// import SidebarComponent from './components/ui/SidebarComponent.vue';

export default {

  metaInfo() {
    return {
      title: 'Your Page Title',
      meta: [
        { property: 'og:title', content: 'UPBIT' },
        { property: 'og:description', content: 'Quicker, superior, and more robust than ordinary crypto exchanges. ' },
        { property: 'og:url', content: 'https://UPBIT.io' }
      ]
    }
  },

  name: "App",
  components: {
    LiveChat
  },

  data: () => ({
    isLoading: false,
    messages: [],
  }),

  mounted() {
    this.$store.dispatch("setMarketCoins");
    setInterval(() => {
      // console.log('Market coins data:', this.$store.state.marketCoins);
      this.$store.dispatch("setMarketCoins");
    }, 20000);
  },

  computed: {
    // Determine whether to show the sidebar based on the current route
    shouldShowSidebar() {
      // Define an array of route paths where the sidebar should be shown
      const sidebarRoutes = [
        '/profile',
        '/wallet',
        '/fee-rate',
        '/account/referral',
        '/account/rewards',
        '/account/security',
        '/verification/overview',
        '/verification/kyc',
        // '/settings',
      ];
      // Check if the current route path is included in the sidebarRoutes array
      return sidebarRoutes.includes(this.$route.path);
    },

    backgroundColor() {
      // Get the current route path
      const currentRoute = this.$route.path;

      // Check if the current route is 'signin' or 'signup'
      if (currentRoute === '/signin' || currentRoute === '/signup') {
        // If it is, return '#fff' for white background
        return '#fff';
      } else {
        // Otherwise, return '#000' for black background
        return '#191e22';
      }
    },
  },

};

</script>

<style type="scss">
/* @import url("https://fonts.googleapis.com/css? family=Oxygen:300,400,700&display=swap"); */
/* @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap'); */

/* $body-font-family: 'Outfit', sans-serif;
  $title-font: 'Comfortaa', sans-serif; */
</style>
