<template>
   <v-row>
      <v-col v-if="!$vuetify.breakpoint.xs" class="mt-5" cols="12">

         <v-data-table :loading="isLoading" :headers="headers" :items="funds" :items-per-page="5"
            class="transparent elevation-1 pa-5">
            <template v-slot:item.type="{ item }">
               <div class="d-flex align-center">
                  <div class="mr-2">
                     <v-chip v-if="item.delegate_type == 'BUY'" color="0ECB81">{{ $t('trade.buy') }}</v-chip>
                     <v-chip v-if="item.delegate_type == 'SELL'" color="CA3F64">{{ $t('trade.sell') }}</v-chip>
                  </div>
               </div>
            </template>
            <template v-slot:item.amount="{ item }">
               <div class="d-flex align-center">
                  <div class="mr-2">
                     <span v-if="item.delegate_type == 'BUY'">{{ Number(item.number_of_order) /
                        Number(item.final_price) }}</span>
                     <span v-if="item.delegate_type == 'SELL'">{{ Number(item.number_of_order) *
                        Number(item.final_price) }}</span>
                  </div>
               </div>
            </template>
            <template v-slot:item.total="{ item }">
               <div class="d-flex align-center">
                  <div class="mr-2">
                     <span>{{ Number(item.number_of_order) * Number(item.final_price) }}</span>
                  </div>
               </div>
            </template>
         </v-data-table>

      </v-col>

      <v-col v-else class="mt-5" cols="12">
         <div v-if="isLoading" class="text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
         </div>

         <div v-else>
            <v-row v-for="{ delegate_type, number_of_order, coin, wallet_balance } in funds">
               <v-col cols="12">
                  <div class="d-flex justify-space-between">
                     <span class="value">
                        {{ coin.name }}
                     </span>
                  </div>

                  <div class="d-flex justify-space-between">

                     <span class="label">
                        {{ $t('trade.walletBalance') }}
                     </span>

                     <span class="value">
                        {{ wallet_balance }}
                     </span>
                  </div>

               </v-col>
               <v-divider color="#b7bdc6 "></v-divider>
            </v-row>
         </div>

      </v-col>

   </v-row>
</template>

<script>

import { getfunds } from '@/services/Trades';

export default {
   data() {
      return {
         isLoading: false,
         funds: [],
         headers: [
            {
               value: "coin.name",
               text: "Token",
               align: "start",
               sortable: true,
            },
            {
               value: "wallet_balance",
               text: "Balance",
               sortable: true,
               align: "end",
            },

            // {
            //   value: "Cost",
            //   text: "Price (USDT)",
            //   sortable: true,
            //   align: "center",
            // },
            // {
            //   value: "amount",
            //   text: "Filled",
            //   sortable: true,
            //   align: "center",
            // },
            // {
            //   value: "total",
            //   text: "Total (USDT)",
            //   sortable: true,
            //   align: "center",
            // },
         ],
      }
   },
   methods: {
      async fetchfunds() {
         this.$axios.get('https://api.ubcoins.co/api/v3/trades/funds')
            .then(response => {
               this.funds = response.data.funds
            })
      },
   },

   mounted() {
      this.fetchfunds()
      console.log('working')
   }
}
</script>

<style>
.label {
   font-size: 14px;
   color: #b7bdc6;
}

.value {
   font-size: '14px';
   font-weight: 500;
}
</style>