<template>
  <v-row justify="center">
    <v-col cols="5">
      <!-- <v-toolbar>
            <v-btn icon color="secondary" to="/account">
                <icon
                    icon="material-symbols:arrow-back"
                    width="24"
                    height="24"
                ></icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-title class="">Tasks Center</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn to="settings" icon>
                <icon icon="heroicons:cog-solid" width="24" height="24" />
            </v-btn>
        </v-toolbar> -->

      <v-container v-if="!loading">
        <div v-if="tasks.length > 0">
          <v-card class="mt-4" v-for="(task, index) in tasks" :key="index"
            :disabled="task.task_status != 'ONGOING' ? true : false">
            <v-card-text>
              <v-row align="start" class="ma-0" @click="coinClicked()">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="9" class="pt-0 pb-1">
                      <h6 class="font-weight-bold white--text">
                        {{ task.task_center.description }}
                      </h6>
                    </v-col>
                    <v-col cols="3" class="text-right ma-0 pa-0">
                      <v-chip :color="task.task_status == 'DONE'
                        ? 'greenPrimary'
                        : 'secondary'
                        " small label :disabled="task.task_status != 'ONGOING' ? true : false">
                        {{ task.task_status }}
                      </v-chip>
                    </v-col>
                  </v-row>
                  <v-row align="center" justify="space-between">
                    <v-col cols="7" class="ma-0 pa-0">
                      <v-chip class="ma-2" color="primary" label>
                        <v-icon left> mdi-ticket-confirmation </v-icon>
                        {{ task.task_center.reward_amount }}
                      </v-chip>
                      <v-progress-linear class="ml-2" :value="progress(task)" color="primary" rounded
                        height="6"></v-progress-linear>
                      <span class="ml-2">{{ task.amount }}/{{ task.task_center.amount }}</span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
        <div v-else>
          <v-card class="mt-4 transparent elevation-0">
            <v-card-text class="text-center">
              <v-row align="start" class="ma-0">
                <v-col cols="12">
                  <v-row>
                    <v-col class="pt-0 pb-1">
                      <h5 class="font-weight-bold">No Tasks available</h5>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-container>
      <v-container v-show="loading">
        <v-skeleton-loader v-show="loading" v-for="index in 10" :key="index" type="list-item-three-line"
          class="mt-3"></v-skeleton-loader>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    loading: false,
    tasks: [
      {
        amount: 0,
        task_status: "pending",
        task_center: {
          id: 1,
          description: "Watch 1 video",
          reward_amount: 1,
          amount: 1,
        },
      },
    ],
  }),
  methods: {
    fetchTasks() {
      this.loading = true;
      this.$axios
        .get("https://api.ubcoins.co/api/v3/task/view")
        .then((response) => {
          this.tasks = response.data.tasks;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    progress(task) {
      return (task.amount / task.task_center.amount) * 100;
    },
    claimTask(task) {
      this.loading = true;
      this.$axios
        .get("https://api.ubcoins.co/api/v3/claim-task", {
          params: { task_center_id: task.id },
        })
        .then(() => {
          this.fetchTasks();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.fetchTasks();
  },
};
</script>
