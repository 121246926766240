<template>
  <v-row>

    <grid-layout v-if="!$vuetify.breakpoint.xs" :layout.sync="layout" :responsive-layouts="layouts" :col-num="12"
      :row-height="30" :is-draggable="draggable" :is-resizable="resizable" :vertical-compact="true"
      :use-css-transforms="true" :responsive="true" @breakpoint-changed="breakpointChangedEvent">

      <!-- Stats Header   -->
      <grid-item v-if="layout.length > 0" :key="layout[0].i" :static="layout[0].static" :x="layout[0].x" :y="layout[0].y"
        :w="layout[0].w" :h="layout[0].h" :i="layout[0].i">
        <v-row>
          <v-col class="mb-3 pl-10 pt-5" cols="12" lg="10">
            <div class=" d-flex align-center justify-space-between">

              <div class="d-flex flex-column ">

                <div class="d-flex align-center">
                  <h4 class="mb-0 mr-2" style="cursor: pointer; font-weight: 500;" @click="openDialog()">
                    {{ selectedCoin }}/USDT
                    <br v-if="$vuetify.breakpoint.xs" />
                  </h4>

                  <v-btn icon text @click="openDialog()">
                    <v-icon>mdi-arrow-right</v-icon>
                  </v-btn>
                </div>

                <div class="d-flex mt-1 align-center">

                  <v-skeleton-loader class="ml-2" v-if="!orderPrice" :loading="!orderPrice" type="text" :width="'40px'"
                    :height="'20px'"></v-skeleton-loader>

                  <span v-else style="font-size: 20px; font-weight: 500" class="mr-2">{{ parseFloat(orderPrice).toFixed(2)
                  }}</span>

                  <v-chip small outlined style="margin-left:3px;" v-if="increase_in_value > 0" color="#0ECB81  ">{{
                    parseFloat(increase_in_value).toFixed(2) }}%
                    <v-icon>mdi mdi-trending-up</v-icon>
                  </v-chip>

                  <v-chip small outlined style="margin-left:3px;" v-else-if="increase_in_value < 0" color="#CA3F64 ">{{
                    parseFloat(increase_in_value).toFixed(2) }}%
                    <v-icon>mdi mdi-trending-down</v-icon>
                  </v-chip>

                </div>

                <!-- <h4 class="font-weight-bold mb-0" @click="openDialog()" style="cursor: pointer;">
              {{ selectedCoin }}USDT
            </h4> -->

                <!-- <v-icon style="margin-left:3px;" v-if="selectedMarketCoin.percent > 0"
              color="green">mdi-arrow-top-right-thin</v-icon>
            <v-icon style="margin-left:3px;" v-else-if="selectedMarketCoin.percent < 0"
              color="red">mdi-arrow-top-right-thin</v-icon> -->
              </div>

              <!-- <div v-if="!$vuetify.breakpoint.xs">
            <v-btn text to="/trades/trade-chart/BTC"><v-icon>mdi-chart-arc</v-icon>CHART</v-btn>
            <v-btn text to="/markets/trading_data"><v-icon>mdi-poll</v-icon>MARKET</v-btn>
          </div> -->
            </div>
          </v-col>
        </v-row>
      </grid-item>

      <!-- CoinList -->
      <grid-item style="overflow: auto;" v-if="layout.length > 1" :key="layout[1].i" :static="layout[1].static"
        :x="layout[1].x" :y="layout[1].y" :w="layout[1].w" :h="layout[1].h" :i="layout[1].i">
        <!-- Include the content of the first item -->

        <div class="mx-3 mt-4">
          <span style="font-size: 16px; font-weight: 600;">{{ $t('future.markets') }}</span>
          <v-divider></v-divider>
        </div>

        <div class="mx-3">
          <SearchBarComponent :search.sync="search" placeholder="Search coin" />
        </div>

        <div v-if="filteredData.length <= 0">
          <div class="d-flex align-center justify-center mt-10" style="height: 100%">
            <div class="d-flex flex-column align-center justify-center">
              <v-icon x-large color="#b7bdc6">
                mdi mdi-note-search-outline
              </v-icon>
              <span style="font-size: 12px; color: #b7bdc6">{{ $t('future.noRecords') }}</span>
            </div>
          </div>
        </div>

        <!-- Wrap the content in a div with overflow-y: auto -->
        <div v-else>
          <div class="row-item pa-3" v-for="(item, index) in filteredData" :key="index"
            @click="handleRowClicked(item.coin_pair_name)" style="cursor: pointer">
            <div class="mt-2 d-flex justify-space-between ml-2">
              <div class="d-flex flex-column align-start">
                <v-avatar size="25" color="white">
                  <v-img :src="item.image"></v-img>
                </v-avatar>
                <span style="font-size: 12px;" class="value ">{{ (item.coin_pair_name)
                }}</span>
              </div>
              <div class="d-flex flex-column align-end mr-2">
                <span style="font-size: 12px;">${{ parseFloat(item.close).toFixed(3) }}</span>
                <span v-if="item.increase_in_value > 0" style="color:#0ECB81; font-size: 12px">
                  {{ parseFloat(item.increase_in_value).toFixed(4) }}%
                </span>
                <span v-if="item.increase_in_value < 0" style="color:#CA3F64; font-size: 12px">
                  {{ parseFloat(item.increase_in_value).toFixed(4) }}%
                </span>
              </div>
            </div>
          </div>

        </div>
      </grid-item>

      <!-- Trades Chart -->
      <grid-item style="overflow: auto;" v-if="layout.length > 2" :key="layout[2].i" :static="layout[2].static"
        :x="layout[2].x" :y="layout[2].y" :w="layout[2].w" :h="layout[2].h" :i="layout[2].i">

        <v-col v-if='!$vuetify.breakpoint.xs'>
          <div class="mb-2 d-flex flex-column align-start">
            <v-btn-toggle v-model="sltimeFrame" mandatory dense borderless >
              <v-btn @input="setTimeFrame()" value="1min">
                1min
              </v-btn>
              <v-btn @input="setTimeFrame()" value="5min">
                5min
              </v-btn>
              <v-btn @input="setTimeFrame()" value="1hr">
                1hr
              </v-btn>
              <v-btn @input="setTimeFrame()" value="4hr">
                4hr
              </v-btn>
              <v-btn @input="setTimeFrame()" value="1D">
                1D
              </v-btn>
              <!-- <v-btn value="tradingData">
                Trading Data
              </v-btn> -->
            </v-btn-toggle>
          </div>
          <div v-if="sltimeFrame === '1min'">
            <TVChartContainer/>
          </div>
          <div v-else-if="sltimeFrame === '5min'">
            <TVChartContainerFive />
          </div>
          <div v-else-if="sltimeFrame === '1hr'">
            <TVChartContainerOneHr/>
          </div>
          <div v-else-if="sltimeFrame === '4hr'">
            <TVChartContainerFourHr/>
          </div>
          <div v-else-if="sltimeFrame === '1D'">
            <TVChartContainerOneD/>
          </div>
        </v-col>
      </grid-item>

      <!-- Buy and sell form -->
      <grid-item v-if="layout.length > 3" :key="layout[3].i" :static="layout[3].static" :x="layout[3].x" :y="layout[3].y"
        :w="layout[3].w" :h="layout[3].h" :i="layout[3].i">
        <v-container>
          <v-row justify="center">
            <v-col cols="10">
              <v-form ref="tradeForm" lazy-loading>
                <v-row style="margin-top:5px">
                  <v-col cols="12" class="mb-0">
                    <v-btn-toggle v-model="delegateType" color="transparent" mandatory class="full-width-toggle"
                      @change="setAmountPercentage">
                      <v-btn v-for="(type, index) in delegateTypes" :key="index" :value="type" large
                        :class="delegateType === type ? 'white--text' : 'grey--text'"
                        :color="delegateType === type ? delegateColor : 'greyPrimary'">
                        {{ type }}
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>

                  <v-col cols="12" class="mt-2">

                    <div>
                      <label class="mb-2">{{ $t('future.marketPrice') }}</label>
                      <v-text-field :loading="!orderPrice" :value="orderPrice" disabled placeholder="Market Price" dense
                        outlined></v-text-field>
                    </div>

                    <div>
                      <Label class="mb-3">Leverage {{ $t('future.leverage') }}</Label>
                      <v-select :items="['x10', 'x15', 'x20']" :menu-props="{ bottom: true, offsetY: true }" dense
                        v-model="leverage" outlined class="centered-input">
                      </v-select>
                    </div>

                    <div class="mt-1">
                      <label v-if="priceType === 'COIN'" for="">{{ selectedCoin }}</label>
                      <label v-if="priceType === 'USDT'" for="">USDT</label>
                      <v-text-field class="mt-2" v-model="amount" :value="amount" outlined dense
                        :rules="amountRules()"></v-text-field>
                    </div>

                    <div>
                      <v-slider v-model="amountPercentage" :tick-labels="ticksLabels" ticks="always" tick-size="5"
                        step="25">
                      </v-slider>
                    </div>

                    <!-- <div class="d-flex align-center justify-space-between">
                      <label class="">Amount</label>
                      <v-btn-toggle v-model="amountPercentage" mandatory @input="setAmountPercentage">
                        <v-btn small text>25%</v-btn>
                        <v-btn small text>50%</v-btn>
                        <v-btn small text>75%</v-btn>
                        <v-btn small text>100%</v-btn>
                      </v-btn-toggle>
                    </div> -->

                    <!-- <div class="mt-2">
                      <v-text-field v-model="amount" prepend-inner-icon="mdi-minus" append-icon="mdi-plus"
                        :label="priceType === 'COIN' ? selectedCoin : 'USDT'" :rules="amountRules()">
                      </v-text-field>
                    </div> -->

                    <div>
                      <span style="color: #b7bdc6;"> {{ $t('future.available') }}: </span>
                      <span class="ml-3" :class="balanceToDisplay <= 0 ? '' : ''"
                        style="font-size: 14px; font-weight: 500; ">
                        {{ balanceToDisplay | commaFormat }}
                      </span>
                    </div>

                  </v-col>

                  <v-col cols="12" class="mt-3 py-1">
                    <v-btn block large :color="delegateColor" @click="trade">
                      <span class="text-capitalize">{{ delegateType === "BUY MORE" ? $t('future.buyMore') :
                        $t('future.sellEmpty') }}</span>
                    </v-btn>
                  </v-col>

                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </grid-item>

      <grid-item style="overflow:auto;" v-if="layout.length > 4" :key="layout[4].i" :static="layout[4].static"
        :x="layout[4].x" :y="layout[4].y" :w="layout[4].w" :h="layout[4].h" :i="layout[4].i">
        <v-container>
          <OrderBook :asks="asks" :bids="bids" :price="orderPrice" :selectedCoin="selectedCoin" :isLoading="isLoading" />
        </v-container>
      </grid-item>

      <!-- <grid-item style="overflow: auto" v-if="layout.length > 5" :key="layout[5].i" :static="layout[5].static"
        :x="layout[5].x" :y="layout[5].y" :w="layout[5].w" :h="layout[5].h" :i="layout[5].i">
        <v-container>
          <TradesInfo :selectedCoin="selectedCoin" :TradesData="trades" :price="orderPrice" />
        </v-container>
      </grid-item> -->

      <!-- <grid-item style="overflow: auto" v-if="layout.length > 6 && $vuetify.breakpoint.width < 1450" :key="layout[6].i"
        :static="layout[6].static" :x="layout[6].x" :y="layout[6].y" :w="layout[6].w" :h="layout[6].h" :i="layout[6].i">

        <v-container>
          <v-btn-toggle class="mb-3" v-model="toggleView" mandatory text dense>
            <v-btn value="orderBook" style="text-transform: capitalize;">
              {{ $t(trade.orderBook) }}
            </v-btn>
            <v-btn value="trades" style="text-transform: capitalize">
              {{ $t(trade.marketTrades) }}
            </v-btn>
          </v-btn-toggle>

          <div v-if="toggleView === 'orderBook'">
            <OrderBook :asks="asks" :bids="bids" :price="price" :selectedCoin="selectedCoin" />
          </div>

          <div v-else>
            <TradesInfo :selectedCoin="selectedCoin" :TradesData="trades" :price="price" />
          </div>
        </v-container>
      </grid-item> -->

      <!-- Positions -->
      <grid-item v-if="layout.length > 5" :key="layout[5].i" :static="layout[5].static" :x="layout[5].x" :y="layout[5].y"
        :w="layout[5].w" :h="layout[5].h" :i="layout[5].i">
        <v-tabs v-model="tab" background-color="#00000000" bg-color="transparent" class="tabs-right">
          <v-tab>{{ $t('future.positions') }}</v-tab>
          <v-tab-item>
            <v-row v-if="margins.length > 0" align="center">
              <v-col>
                <v-card color="#00000000" v-for="(margin, index) in margins" :key="index" class="mt-3 pa-5">

                  <v-card-text class="pt-2">
                    <v-row>

                      <div>
                        <span style="font-size: 1.5rem; font-weight: 700;"> {{ margin.coin_pair.pair_name
                        }}</span>
                        <span class="ml-3" style="font-style: 14px; font-weight: 500;">{{ margin.leverage
                        }}x</span>
                      </div>

                      <v-col cols="6" class="py-1">
                        <div class="mt-3 d-flex flex-column">
                          <span class="label">
                            {{ $t('future.unrePnl') }}
                          </span>
                          <span class="value" :class="textColor(margin.income)">
                            {{ Number(margin.total_asset) + Number(margin.total_top_up) }}
                          </span>
                        </div>
                      </v-col>

                      <v-col cols="6" class="text-end py-1">
                        <div class="mt-3 d-flex flex-column">
                          <span class="label">
                            {{ $t('future.roe') }}
                          </span>
                          <span class="value" :class="textColor(margin.rate_of_return * 10)">
                            {{ margin.rate_of_return * 10 }}%
                          </span>
                        </div>
                      </v-col>

                      <v-col cols="4" class="py-1">
                        <div class="mt-3 d-flex flex-column">
                          <span class="label">
                            {{ $t('future.size') }}(USDT)
                          </span>
                          <span class="value">
                            <!-- {{ Number(margin.total_asset) }} -->
                            <!-- {{ Number(margin.total_top_up) }} -->
                            {{ Number(margin.income) + Number(margin.total_top_up) }}
                          </span>
                        </div>
                      </v-col>

                      <v-col cols="4" class="py-1">
                        <div class="mt-3 d-flex flex-column">
                          <span class="label">
                            {{ $t('future.margin') }}(USDT)
                          </span>
                          <span class="value">
                            {{ margin.margin }}
                          </span>
                        </div>
                      </v-col>

                      <v-col cols="4" class="text-end py-1">
                        <div class="mt-3 d-flex flex-column">
                          <span class="label">
                            {{ $t('future.risk') }}
                          </span>
                          <span class="value">
                            --
                          </span>
                        </div>
                      </v-col>

                      <v-col cols="4" class="py-1">
                        <div class="mt-3 d-flex flex-column">
                          <span class="label">
                            {{ $t('future.entryPrice') }}
                          </span>
                          <span class="value">
                            {{ margin.opening_price }}
                          </span>
                        </div>
                      </v-col>

                      <v-col cols="4" class="py-1">
                        <div class="mt-3 d-flex flex-column">
                          <span class="label">
                            {{ $t('future.markPrice') }}
                          </span>
                          <span class="value">
                            --
                          </span>
                        </div>
                      </v-col>

                      <v-col cols="4" class="py-1">
                        <div class="mt-3 d-flex flex-column">
                          <span class="label">
                            {{ $t('future.liqPrice') }}
                          </span>
                          <span class="value">
                            {{ margin.estimated_force_parity }}
                          </span>
                        </div>
                      </v-col>

                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" style="color: #1C1D28 ;" @click="closeMargin(margin.id)">
                      {{ $t('future.closePosition') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col class="text-center">
                <v-img src="../../assets/images/no-data.svg" max-width="200" max-height="200"></v-img>
                <h5 class="mt-5">{{ $t('future.noMargins') }}</h5>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </grid-item>

    </grid-layout>

    <!-- <v-col class="mb-3" cols="12" lg="10">
      <div class="d-flex align-center justify-space-between">

        <div class="d-flex flex-column ">

          <div class="d-flex align-center">
            <h4 class="mb-0 mr-2" style="cursor: pointer; font-weight: 500;" @click="openDialog()">
              {{ selectedCoin }}/USDT
              <br v-if="$vuetify.breakpoint.xs" />
            </h4>

            <v-btn icon text @click="openDialog()">
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </div>

          <div class="d-flex mt-2 align-center">

            <v-skeleton-loader class="ml-2" v-if="!orderPrice" :loading="!orderPrice" type="text" :width="'40px'"
              :height="'20px'"></v-skeleton-loader>

            <span v-else style="font-size: 20px; font-weight: 500" class="mr-2">{{ parseFloat(orderPrice).toFixed(2)
            }}</span>

            <v-chip small outlined style="margin-left:3px;" v-if="increase_in_value > 0" color="#0ECB81  ">{{
              parseFloat(increase_in_value).toFixed(2) }}%
              <v-icon>mdi mdi-trending-up</v-icon>
            </v-chip>

            <v-chip small outlined style="margin-left:3px;" v-else-if="increase_in_value < 0" color="#CA3F64 ">{{
              parseFloat(increase_in_value).toFixed(2) }}%
              <v-icon>mdi mdi-trending-down</v-icon>
            </v-chip>

          </div>

          <h4 class="font-weight-bold mb-0" @click="openDialog()" style="cursor: pointer;">
            {{ selectedCoin }}USDT
          </h4>

          <v-icon style="margin-left:3px;" v-if="selectedMarketCoin.percent > 0"
            color="green">mdi-arrow-top-right-thin</v-icon>
          <v-icon style="margin-left:3px;" v-else-if="selectedMarketCoin.percent < 0"
            color="red">mdi-arrow-top-right-thin</v-icon>
        </div>

        <div v-if="!$vuetify.breakpoint.xs">
          <v-btn text to="/trades/trade-chart/BTC"><v-icon>mdi-chart-arc</v-icon>CHART</v-btn>
          <v-btn text to="/markets/trading_data"><v-icon>mdi-poll</v-icon>MARKET</v-btn>
        </div>
      </div>
    </v-col> -->

    <!-- mobile view -->
    <v-col v-else cols="12">

      <v-row>

        <v-col class="mb-3" cols="12" lg="10">
          <div class="d-flex align-center justify-space-between">

            <div class="d-flex flex-column ">

              <div class="d-flex align-center">
                <h4 class="mb-0 mr-2" style="cursor: pointer; font-weight: 500;" @click="openDialog()">
                  {{ selectedCoin }}/USDT
                  <br v-if="$vuetify.breakpoint.xs" />
                </h4>

                <v-btn icon text @click="openDialog()">
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </div>

              <div class="d-flex mt-2 align-center">

                <v-skeleton-loader class="ml-2" v-if="!orderPrice" :loading="!orderPrice" type="text" :width="'40px'"
                  :height="'20px'"></v-skeleton-loader>

                <span v-else style="font-size: 20px; font-weight: 500" class="mr-2">{{ parseFloat(orderPrice).toFixed(2)
                }}</span>

                <v-chip small outlined style="margin-left:3px;" v-if="increase_in_value > 0" color="#0ECB81  ">{{
                  parseFloat(increase_in_value).toFixed(2) }}%
                  <v-icon>mdi mdi-trending-up</v-icon>
                </v-chip>

                <v-chip small outlined style="margin-left:3px;" v-else-if="increase_in_value < 0" color="#CA3F64 ">{{
                  parseFloat(increase_in_value).toFixed(2) }}%
                  <v-icon>mdi mdi-trending-down</v-icon>
                </v-chip>

              </div>

              <!-- <h4 class="font-weight-bold mb-0" @click="openDialog()" style="cursor: pointer;">
              {{ selectedCoin }}USDT
            </h4> -->

              <!-- <v-icon style="margin-left:3px;" v-if="selectedMarketCoin.percent > 0"
              color="green">mdi-arrow-top-right-thin</v-icon>
            <v-icon style="margin-left:3px;" v-else-if="selectedMarketCoin.percent < 0"
              color="red">mdi-arrow-top-right-thin</v-icon> -->
            </div>

            <!-- <div v-if="!$vuetify.breakpoint.xs">
            <v-btn text to="/trades/trade-chart/BTC"><v-icon>mdi-chart-arc</v-icon>CHART</v-btn>
            <v-btn text to="/markets/trading_data"><v-icon>mdi-poll</v-icon>MARKET</v-btn>
          </div> -->
          </div>
        </v-col>


        <v-col v-if="$vuetify.breakpoint.xs" cols="12" sm="12" lg="12">

          <div class="mb-10">
            <div class="mb-2 d-flex flex-column align-start">
              <v-btn-toggle v-model="sltimeFrame" mandatory dense borderless >
                <v-btn @input="setTimeFrame()" value="1min">
                  1min
                </v-btn>
                <v-btn @input="setTimeFrame()" value="5min">
                  5min
                </v-btn>
                <v-btn @input="setTimeFrame()" value="1hr">
                  1hr
                </v-btn>
                <v-btn @input="setTimeFrame()" value="4hr">
                  4hr
                </v-btn>
                <v-btn @input="setTimeFrame()" value="1D">
                  1D
                </v-btn>
                <!-- <v-btn value="tradingData">
                  Trading Data
                </v-btn> -->
              </v-btn-toggle>
            </div>
            <div v-if="sltimeFrame === '1min'">
              <TVChartContainer/>
            </div>
            <div v-else-if="sltimeFrame === '5min'">
              <TVChartContainerFive />
            </div>
            <div v-else-if="sltimeFrame === '1hr'">
              <TVChartContainerOneHr/>
            </div>
            <div v-else-if="sltimeFrame === '4hr'">
              <TVChartContainerFourHr/>
            </div>
            <div v-else-if="sltimeFrame === '1D'">
              <TVChartContainerOneD/>
            </div>
          </div>


          <div>
            <v-slide-group mandatory class="mb-3" v-model="selectedOption" active-class="#5E6673">
              <v-slide-item v-for="(option, index) in options" :key="index" v-slot="{ active, toggle }">
                <v-btn small :color="active ? '#FAFAFA' : '#b7bdc6 '" text class="mx-1" :input-value="active"
                  @click="toggle">
                  {{ option }}
                </v-btn>
              </v-slide-item>
            </v-slide-group>
          </div>

          <div v-if="selectedOption === 0">
            <v-form ref="tradeForm" lazy-loading>
              <v-row style="margin-top:5px">

                <v-col cols="12" class="mb-0">

                  <v-btn-toggle v-model="delegateType" color="transparent" mandatory class="full-width-toggle"
                    @change="setAmountPercentage">
                    <v-btn v-for="(type, index) in delegateTypes" :key="index" :value="type" large
                      :class="delegateType === type ? 'white--text' : 'grey--text'"
                      :color="delegateType === type ? delegateColor : 'greyPrimary'">
                      {{ type }}
                    </v-btn>
                  </v-btn-toggle>

                </v-col>

                <v-col cols="12" class="mt-2">
                  <div>
                    <v-text-field disabled label="Market Price" dense outlined></v-text-field>
                  </div>

                  <div>
                    <Label class="mb-3">{{ $t('future.leverage') }}</Label>
                    <v-select :items="['x10', 'x15', 'x20']" dense v-model="leverage" outlined class="centered-input">
                    </v-select>
                  </div>

                  <div class="mt-1">
                    <label v-if="priceType === 'COIN'" for="">{{ selectedCoin }}</label>
                    <label v-if="priceType === 'USDT'" for="">USDT</label>
                    <v-text-field class="mt-2" v-model="amount" :value="amount" outlined dense
                      :rules="amountRules()"></v-text-field>
                  </div>

                  <div>
                    <v-slider v-model="amountPercentage" :tick-labels="ticksLabels" ticks="always" tick-size="5"
                      step="25">
                    </v-slider>
                  </div>

                  <!-- <div class="d-flex align-center justify-space-between">
                      <label>Amount</label>
                      <v-btn-toggle v-model="amountPercentage" mandatory @input="setAmountPercentage">
                        <v-btn small text>25%</v-btn>
                        <v-btn small text>50%</v-btn>
                        <v-btn small text>75%</v-btn>
                        <v-btn small text>100%</v-btn>
                      </v-btn-toggle>
                    </div>

                    <div>
                      <v-text-field v-model="amount" prepend-inner-icon="mdi-minus" append-icon="mdi-plus"
                        :label="priceType === 'COIN' ? selectedCoin : 'USDT'" :rules="amountRules()">
                      </v-text-field>
                    </div> -->

                </v-col>

                <div class="d-flex align-center">
                  <span style="color: #b7bdc6;">{{ $t('future.available') }}:</span>
                  <span class="ml-3" :class="balanceToDisplay <= 0 ? '' : ''" style="font-size: 14px; font-weight: 500; ">
                    {{ balanceToDisplay | commaFormat }}
                  </span>
                </div>

                <v-col cols="12" class="mt-3 py-1">
                  <v-btn class="mb-3" block large :color="delegateColor" @click="trade">
                    <span class="text-capitalize">{{ delegateType === "BUY MORE" ? $t('future.buyMore') :
                      $t('future.buyMore')
                    }}</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </div>

          <div v-if="selectedOption === 1">
            <OrderBook :asks="asks" :bids="bids" :price="price" :selectedCoin="selectedCoin" />
          </div>
        </v-col>

      </v-row>

      <v-divider color="#b7bdc6 "></v-divider>

      <v-row v-for="(margin, index) in margins" :key="index">
        <v-col cols="12">

          <div>
            <span style="font-size: 1.5rem; font-weight: 700;"> {{ margin.coin_pair.pair_name }}</span>
            <span class="value ml-3">{{ margin.leverage }}x</span>
          </div>
          <div class="mt-3 d-flex flex-column">
            <span class="label">
              {{ $t('future.unrePnl') }}
            </span>
            <span class="value" :class="textColor(margin.income)">
              {{ Number(margin.total_asset) + Number(margin.total_top_up) }}
            </span>
          </div>

          <div class="mt-1 d-flex align-center justify-space-between">
            <span class="label">
              {{ $t('future.roe') }}
            </span>
            <span class="value" :class="textColor(margin.rate_of_return * 10)">
              {{ margin.rate_of_return * 10 }}%
            </span>
          </div>

          <div class="mt-1 d-flex align-center justify-space-between">
            <span class="label">
              {{ $t('future.size') }}(USDT)
            </span>
            <span class="value">
              {{ Number(margin.income) + Number(margin.total_top_up) }}
            </span>
          </div>

          <div class="mt-1 d-flex align-center justify-space-between">
            <span class="label">
              {{ $t('future.margin') }}(USDT)
            </span>
            <span class="value">
              {{ margin.margin }}
            </span>
          </div>


          <div class="mt-1 d-flex align-center justify-space-between">
            <span class="label">
              {{ $t('future.risk') }}
            </span>
            <span class="value">
              --
            </span>
          </div>

          <div class="mt-1 d-flex align-center justify-space-between">
            <span class="label">
              {{ $t('future.entryPrice') }}
            </span>
            <span class="value">
              {{ margin.opening_price }}
            </span>
          </div>

          <div class="mt-1 d-flex align-center justify-space-between">
            <span class="label">
              {{ $t('future.markPrice') }}
            </span>
            <span class="value">
              --
            </span>
          </div>

          <div class="mt-1 d-flex align-center justify-space-between">
            <span class="label">
              {{ $t('future.liqPrice') }}
            </span>
            <span class="value">
              {{ margin.estimated_force_parity }}
            </span>
          </div>

          <div class="mt-5">
            <v-btn large color="primary" block style="color: #1C1D28;" @click="closeMargin(margin.id)">
              {{ $t('future.closePosition') }}
            </v-btn>
          </div>

        </v-col>

        <v-divider color="#b7bdc6"></v-divider>
      </v-row>
    </v-col>

    <v-dialog v-model="confirmDialog" persistent width="400px">
      <v-card width="400px">
        <v-card-title>
          <v-toolbar class="elevation-0 transparent">
            <v-spacer></v-spacer>
            <v-toolbar-title>{{ $t('dialog.confirmation') }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-form ref="confirmationForm" lazy-loading>
            <v-row>
              <v-col cols="12">
                <label>{{ $t('dialog.transPassword') }} </label>
                <v-text-field v-model="password" :append-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="visible = !visible" :rules="passwordRules()" :type="visible ? 'text' : 'password'"
                  :error-messages="errorMessage.password" dense />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" large text @click="confirmDialog = false">
            {{ $t('dialog.cancel') }}
          </v-btn>
          <v-btn color="primary" class="px-4" large rounded @click="trade(), (loader = 'loading')" :loading="loading"
            :disabled="loading">
            {{ $t('dialog.confirm') }}
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="coinsDialog" max-width="0" class="elevation-0" color="#00000000">
      <v-card class="right-dialog ">
        <!-- <v-card-text>
          <div class="d-flex align-center">
            <v-btn icon @click="$router.push('/wallet');" class="mr-2">
              <v-icon>mdi mdi-chevron-left</v-icon>
            </v-btn>
            Wallet
          </div>
        </v-card-text> -->

        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="coinsDialog = false">
            <v-icon>mdi mdi-close </v-icon>
          </v-btn>
        </v-card-title>

        <v-row align="center">
          <v-col cols="12">
            <SearchBarComponent :search.sync="search" placeholder="Search Coin" />
            <v-data-table @click:row="rowClicked($event)" :headers="headers" :items="filteredData"
              :hide-default-footer="true" class="transparent px-3">

              <template v-slot:item.name="{ item }">
                <div class="d-flex align-center">
                  <v-avatar size="30">
                    <v-img :src="item.image"></v-img>
                  </v-avatar>
                  <span class="ml-2" style="font-size: 14px; font-weight: 500;">
                    {{ item.coin_pair_name.replace('USDT', '') }}
                  </span><br />
                  <!-- <span style="font-size: 14px; font-weight: 500; color:#b7bdc6 ">{{
              (item.coin_pair_name).replace('USDT', '') }}</span> -->
                  <!-- <span> {{ item.date }}</span><br/> -->
                </div>
              </template>

              <template v-slot:item.close="{ item }">
                <span style="font-size: 14px; font-weight: 500;">${{ parseFloat(item.close).toFixed(3) }}</span>
                <!-- <span style=" font-size:10px;color:grey">{{ (item.coin_pair_name).replace('USDT', '')}}</span>
        -->
                <!-- <span> {{ item.date }}</span><br/> -->
              </template>

              <template v-slot:item.increase_in_value="{ item }">

                <span v-if="item.increase_in_value > 0" style="color:#0ECB81; font-size: 14px; font-weight: 500;">
                  {{ parseFloat(item.increase_in_value).toFixed(4) }}%
                  <v-icon style="color:#0ECB81 " class="ml-0">mdi mdi-trending-up</v-icon>
                </span>

                <span v-if="item.increase_in_value < 0" style="color:#CA3F64; font-size: 14px; font-weight: 500; ">{{
                  parseFloat(item.increase_in_value).toFixed(4) }}%
                  <v-icon style="color:#CA3F64  " class="ml-0">mdi
                    mdi-trending-down</v-icon>
                </span>

              </template>
              <template v-slot:item.volume="{ item }">
                <span>{{ parseFloat(item.volume).toFixed(2) }}</span><br />
              </template>
            </v-data-table>
          </v-col>
        </v-row>

      </v-card>
    </v-dialog>

  </v-row>
</template>
<script>

import axios from 'axios'
import TVChartContainer from '../../../components/TVChartContainer.vue';
import TVChartContainerFive from '../../../components/TVChartContainerFive.vue'
import TVChartContainerOneHr from '../../../components/TVChartContainerOneHr.vue'
import TVChartContainerFourHr from '../../../components/TVChartContainerFourHr.vue'
import TVChartContainerOneD from '../../../components/TVChartContainerOneD.vue'
import SearchBarComponent from '@/components/ui/SearchBarComponent.vue';
import OrderBook from './OrderBook.vue';
import TradesInfo from './TradesInfo.vue'

import { GridLayout, GridItem } from "vue-grid-layout";

let testLayouts = {

  sm: [
    { "x": 0, "y": 0, "w": 12, "h": 2.5, "i": "0", static: true },
    { "x": 0, "y": 0, "w": 0, "h": 0, "i": "1", static: true }, //remove coin list
    { "x": 0, "y": 2.5, "w": 6, "h": 15, "i": "2", static: true }, //Charts
    { "x": 0, "y": 17.5, "w": 6, "h": 15, "i": "3", static: true },//buy and sell form
    { "x": 20, "y": 0, "w": 10, "h": 25, "i": "4", static: true }, //order book
    { "x": 32, "y": 0, "w": 10, "h": 17.5, "i": "5", static: true }, //Positions
  ],

  md: [
    { "x": 0, "y": 0, "w": 12, "h": 2.5, "i": "0", static: true },//header
    { "x": 0, "y": 0, "w": 0, "h": 0, "i": "1", static: true }, //remove coin list
    { "x": 0, "y": 2.5, "w": 6, "h": 15, "i": "2", static: true }, //Chart
    { "x": 0, "y": 17.5, "w": 6, "h": 14.5, "i": "3", static: true },//buy and sell form
    { "x": 6, "y": 2.5, "w": 4, "h": 29.5, "i": "4", static: true }, //order book
    { "x": 0, "y": 32, "w": 12, "h": 20, "i": "5", static: true },//Positions
  ],

  lg: [
    { "x": 0, "y": 0, "w": 12, "h": 2.5, "i": "0", static: true },//header
    { "x": 0, "y": 2.5, "w": 2, "h": 30, "i": "1", static: true },//Coins
    { "x": 2, "y": 2.5, "w": 6, "h": 15, "i": "2", static: true },//Charts
    { "x": 2, "y": 17.5, "w": 6, "h": 15, "i": "3", static: true }, //Buy and Sell Form
    { "x": 8, "y": 2.5, "w": 4, "h": 30, "i": "4", static: true }, //OrderBook 
    { "x": 10, "y": 32.5, "w": 12, "h": 18, "i": "5", static: true },//Positions
  ],
};

export default {
  components: {
    TVChartContainer,
    TVChartContainerFive,
    TVChartContainerOneHr,
    TVChartContainerFourHr,
    TVChartContainerOneD,
    OrderBook,
    TradesInfo,
    SearchBarComponent,
    GridLayout,
    GridItem
  },
  name: "futures",
  data: () => ({
    sltimeFrame: '1min',
    ticksLabels: [
      '0',
      '25',
      '50',
      '75',
      '100',
    ],
    search: '',
    selectedOption: 0,
    price: "",
    trades: [],
    coin_base: "",
    options: ['Buy and Sell', 'Order Books'],
    isLoading: false,
    orderBookData: null,
    asks: [],
    bids: [],
    orderBookSocket: null,

    layouts: testLayouts,
    layout: testLayouts["lg"],
    draggable: true,
    resizable: true,
    index: 0,

    headers: [
      {
        value: "name",
        text: "Name",
        align: "start",
        sortable: true,
      },
      {
        value: "close",
        text: "Price",
        sortable: true,
        align: "start",
      },
      {
        value: "increase_in_value",
        text: "24h Change",
        sortable: true,
        align: "center",
      },
    ],
    orderPrice: '',
    coinsDialog: false,
    powPairs: [],
    drawer: false,
    navSelectedCoin: 0,
    loading: false,
    visible: false,
    marketCoinsDialog: false,
    tradeType: 2,
    delegateTypes: ["BUY MORE", "SELL EMPTY"],
    priceTypes: ["USDT", "COIN"],
    leverage: "x10",
    priceType: "USDT",
    amountPercentage: 0,
    amount: null,
    password: null,
    margins: [],
    minimum: {
      minimum_sell: 0,
      account_balance_limit_spot: 0,
    },
    errorMessage: {
      password: "",
    },
    latestDetails: {
      amount: 0,
      percent: 0,
    },
    previousPath: "",
    confirmDialog: false,
    increase_in_value: "",
  }),

  computed: {
    sheetStyle() {
      if (this.$vuetify.breakpoint.xs) {
        // If the screen size is xs, return an empty object to remove the style
        return {
          borderRadius: null,
          border: null
        };
      } else {
        // If the screen size is not xs, return the default style
        return {
          borderRadius: '20px',
          border: '1px solid #2b3139'
        };
      }
    },

    delegateType: {
      get() {
        return this.$store.state.futureDelegateType;
      },
      set(value) {
        this.$store.state.futureDelegateType = value;
      },
    },
    selectedCoin: {
      get() {
        return this.$store.state.selectedCoin;
      },
      set(value) {
        this.$store.state.selectedCoin = value;
      },
    },
    selectedMarketCoin() {
      return this.$store.getters.marketCoins.find(
        (coin) => coin.name == this.selectedCoin
      );
    },
    marketCoins() {
      return this.$store.getters.marketCoins;
    },
    coins() {
      //Get name only
      return this.$store.getters.marketCoins.map((coin) => coin.name);
    },
    numberOfOrder() {
      if (this.priceType === "USDT") {
        return this.amount / this.valuePerUnit;
      } else {
        return this.amount;
      }
    },
    total() {
      if (this.priceType === "USDT") {
        return this.amount;
      } else {
        return this.amount * this.valuePerUnit;
      }
    },
    valuePerUnit() {
      return this.selectedMarketCoin.value;
    },
    balanceInUSDT() {
      let wallets = this.$store.getters.futureWallet.wallet;
      return wallets.find((item) => item.name === this.selectedCoin).usdt_value;
    },
    balanceInCoin() {
      let wallets = this.$store.getters.futureWallet.wallet;
      let balance = wallets.find(
        (item) => item.name === this.selectedCoin
      ).coin_value;

      return balance;
    },
    balanceToDisplay() {
      return this.priceType == "COIN" ? this.balanceInCoin : this.balanceInUSDT;
    },
    minimumSell() {
      if (this.minimum.minimum_sell == "0") return 0;
      else {
        if (this.priceType == "COIN") return this.minimum.minimum_sell;
        else return this.minimum.minimum_sell * this.valuePerUnit;
      }
    },
    minimumBuy() {
      if (this.minimum.account_balance_limit_spot == "0") return 0;
      else {
        if (this.priceType == "TOTAL")
          return this.minimum.account_balance_limit_spot;
        else return this.minimum.account_balance_limit_spot * 1;
      }
    },
    delegateColor() {
      return this.delegateType == "SELL EMPTY" ? "redPrimary" : "greenPrimary";
    },
    orders: function () {
      return this.$store.state.orders;
    },

    filteredData() {
      if (!this.search) {
        return this.powPairs;
      }
      const searchTerm = this.search.toLowerCase();
      return this.powPairs.filter(item => item.coin_pair_name.toLowerCase().includes(searchTerm));
    },
  },
  methods: {
    setTimeFrame()
    {
      sessionStorage.setItem('slTimeFrame',this.sltimeFrame)
    },

    handleRowClicked(value) {
      console.log(value)
      const name = (value).replace('USDT', '')
      this.$store.state.selectedCoin = name;
      if (this.$route.path !== `/trades/futures/${name}`) {
        this.$router.push(`/trades/futures/${name}`);
        location.reload()
      }
    },

    breakpointChangedEvent(newBreakpoint, newLayout) {
      // Update the layout based on the current breakpoint
      if (newBreakpoint === "md") {
        this.layout = this.layouts.md;
      } else if (newBreakpoint === "lg") {
        this.layout = this.layouts.lg;
      }
      // Add more conditions for other breakpoints if needed
    },

    fetchMargins() {
      this.loading = true;
      this.$axios
        .get("https://api.ubcoins.co/api/v2/client-margin")
        .then((response) => {
          this.margins = response.data.margin;
          this.loading = false;
        });
    },

    rowClicked(value) {
      const name = value.coin_pair_name.replace('USDT', '');
      this.$store.state.selectedCoin = name;

      if (this.$route.path !== `/trades/futures/${name}`) {
        this.$router.push(`/trades/futures/${name}`);
        this.coinsDialog = false
        location.reload()
      }
    },

    fetchPrice() {
      this.$axios.get('https://api.ubcoins.co/api/v3/coin-pair/current-price', {
        params: {
          timeframe: '1min',
          symbol: this.selectedCoin,
        }
      }).then(response => {
        this.orderPrice = response.data.chart.close
        this.increase_in_value = response.data.chart.increase_in_value
        console.log(this.orderPrice)
      })
    },

    getHistoricalData() {
      const historicalDataString = localStorage.getItem("historicalData");

      // Check if historicalDataString is not null or undefined before parsing
      if (historicalDataString) {
        const historicalDataLocal = JSON.parse(historicalDataString);
        this.historicalData = historicalDataLocal;
        this.price = this.historicalData.bars[5000].close
        this.high = this.historicalData.bars[5000].high
        this.low = this.historicalData.bars[5000].low
        // this.volume = this.historicalData.bars[999].volume
        // Now historicalData contains the parsed data from localStorage
      } else {
        // Handle the case when historicalDataString is null or undefined
      }

    },

    getData() {
      axios.get('https://api.ubcoins.co/api/v3/coin-assets', {
        params: {
          name: this.selectedCoin
        }
      }).then(response => {
        axios.get('https://www.okx.com/api/v5/market/ticker', {
          params: {
            instId: response.data.coin.coin_base + '-USD-SWAP'
          }
        })
          .then(response => {
            if (response.data && response.data.data && response.data.data.length > 0) {
              const data = response.data.data[0];
              if (data.vol24h) {
                this.volume = data.vol24h;
              }
              if (data.volCcy24h) {
                this.volumeCCY = data.volCcy24h;
              }
            } else {
              console.error('Data is empty or undefined.');
            }
          })

      })
      this.change24hr = this.selectedMarketCoin.percent
    },

    openDialog() {
      // console.log(this.dialog); // Log the current value of dialog
      this.coinsDialog = true;
    },

    closeDepositDialog() {
      this.coinsDialog = false;
    },

    toggleDrawer() {
      this.drawer = !this.drawer;
    },

    pow() {
      axios.get('https://api.ubcoins.co/api/v3/inno-coins', {
        params: {
          pair_type: 'ALT'
        }
      })
        .then(response => {
          this.powPairs = response.data.coinpair
        })
    },

    selectCoin(coin) {
      this.$router.push(`/trades/futures/${coin.name}`);
      this.navSelectedCoin = this.marketCoins.indexOf(coin) + 1;
      this.selectedCoin = coin.name;
      this.fetchMinimum(coin.name);
      location.reload()
      // this.$route.params.coin = coin.name;
      // this.marketCoinsDialog = false;
    },
    fetchMinimum(coin) {
      this.$axios
        .get(`https://api.ubcoins.co/api/v3/getOrder?symbol=${coin}`)
        .then((response) => {
          this.minimum = response.data.price;
        });
    },
    fetchMargins() {
      this.loading = true;
      this.$axios
        .get("https://api.ubcoins.co/api/v2/client-margin")
        .then((response) => {
          this.margins = response.data.margin;
          this.loading = false;
        });
    },
    amountRules() {
      return this.delegateType === "SELL EMPTY"
        ? [
          (v) => !!v || "Amount is required",
          (v) =>
            v >= this.minimumSell ||
            `Amount must be greater than minimum sell amount (${this.minimumSell})`,
          (v) =>
            v <= this.balanceToDisplay ||
            `Insufficient balance (${this.balanceToDisplay})`,
        ]
        : [
          (v) => !!v || "Amount is required",
          (v) =>
            v >= this.minimumBuy ||
            `Amount must be greater than minimum buy amount (${this.minimumBuy})`,
          (v) =>
            v <= this.balanceToDisplay ||
            `Insufficient balance (${this.balanceToDisplay})`,
        ];
    },
    passwordRules() {
      return [(v) => !!v || "Password is required"];
    },
    trade() {
      if (this.$refs.tradeForm.validate()) {
        this.loading = true;
        this.$axios
          .post("https://api.ubcoins.co/api/v2/buy-more", {
            name: this.selectedCoin,
            delegate_type: this.delegateType //BUY MORE, SELL EMPTY
              .replace(" ", "_"),
            margin: this.total,
            leverage: this.leverage.replace("x", ""),
            opening_price: this.valuePerUnit,
          })
          .then((response) => {
            this.loading = false;
            if (response.data.message == "success") {
              this.$swal({
                title: "Success",
                text: "Order created successfully",
                icon: "success",
                timer: 3000,
                buttons: false,
              });
              this.$store.dispatch("setFutureWallet");
            } else if (response.data.message === "wrong_transaction_password") {
              this.$swal({
                title: "Error",
                text: "Wrong transaction password",
                icon: "error",
                timer: 3000,
                buttons: false,
              });
              this.errorMessage.password = "Wrong transaction password";
            } else {
              this.$swal({
                title: "Error",
                text: "Something went wrong",
                icon: "error",
                timer: 3000,
                buttons: false,
              });
            }
          })
          .catch((error) => {
            this.loading = false;
            this.$swal({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 3000,
              buttons: false,
            });
          });
      }
    },
    ratingText(index) {
      return index == 0
        ? "25%"
        : index == 1
          ? "50%"
          : index == 2
            ? "75%"
            : "100%";
    },
    closeMargin(id) {
      this.$axios
        .get("https://api.ubcoins.co/api/v3/futures/close", {
          params: { future_id: id },
        })
        .then((response) => {
          if (response.data.message == "closeRequest") {
            this.$swal({
              title: "Success",
              //  text: "Please proceed to Customer Service for confirmation.",
              icon: "success",
              timer: null,
              buttons: false,
            });
            this.$store.dispatch("setFutureWallet");
            this.$router.push("/");
          } else if (response.data.message === "closePositionError") {
            this.$swal({
              html: `Remaining days: ${response.data.daysLeft} <br/>
                                If your income amount is greater than or equal to ${response.data.futureTarget},
                                or total assets is less than equal to ${response.data.futureFundsToAdd}, can be closed in advance directly.`,
              icon: "error",
              showCancelButton: false,
            });
            this.errorMessage.password = "Wrong transaction password";
          } else {
            this.$swal({
              title: "Error",
              text: "Something went wrong",
              icon: "error",
              timer: 3000,
              buttons: false,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$swal({
            title: "Error",
            text: error.response.data.message,
            icon: "error",
            timer: 3000,
            buttons: false,
          });
        });
    },
    closeAllMargins() {
      this.margins.forEach((margin) => {
        this.closeMargin(margin.id);
      });
    },

    setAmountPercentage() {
      if (this.amountPercentage == 0) {
        this.amount = 0;
      }

      if (this.amountPercentage == 25) {
        this.amount = this.balanceToDisplay / 4;
      }
      if (this.amountPercentage == 50) {
        this.amount = this.balanceToDisplay / 2;
      }
      if (this.amountPercentage == 75) {
        this.amount = this.balanceToDisplay / 4 + (this.balanceToDisplay / 2);
      }
      if (this.amountPercentage == 100) {
        this.amount = this.balanceToDisplay;
      }

    },

    textColor(amount) {
      //Return the color based on the percent value if positive or negative
      return amount >= 0
        ? "greenPrimary--text font-weight-bold mb-0"
        : "redPrimary--text font-weight-bold mb-0";
    },

    fetchTrades() {
      this.$axios.get('https://www.okx.com/api/v5/market/trades', {
        params: {
          instId: this.selectedCoin + '-USDT',
          limit: '100'
        }
      }).then(response => {
        // console.log(response)
        this.trades = response.data.data
      })
    },

    async getTradesChart() {
      try {
        const response = await this.$axios.get(
          `https://api.ubcoins.co/api/v2/chart/chart-series?symbol=${this.selectedCoin}&timeframe=${this.timeFrame}`
        );
        //Save marketCoins to vuex
        // this.$store.commit("setMarketCoins", response.data.coinpair);
        this.dataRaw = response.data;
        //Get current price
        // this.coinDetails.amount = this.dataRaw.latest_time.close;
        this.latestDetails = this.dataRaw.latest_time;
        //Get percent change
        // this.coinDetails.percent = this.dataRaw.latest_time.percent;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    async fetchOrders() {
      try {
        this.$axios
          .get(`https://api.ubcoins.co/api/v2/recent-trades`, {
            params: {
              name: this.selectedCoin + "USDT",
            },
          })
          .then((response) => {
            //Save Orders to vuex
            this.$store.commit("setOrders", response.data);
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
        return [];
      }
    },
  },

  watch: {
    amountPercentage(newVal) {
      this.setAmountPercentage();
    },

    sellAmountPercentage(newVal) {
      this.setSellAmountPercentage();
    }
  },

  mounted() {
    // fetchPrice
    this.fetchTrades()
    this.fetchMargins()
    this.timer1 = setInterval(this.fetchTrades, 3000)
    this.fetchPrice

    setInterval(this.fetchPrice, 5000)
    setInterval(this.getData, 5000)

    setInterval(this.fetchMargins, 5000)


    this.orderBookSocket = new WebSocket('wss://ws.okx.com:8443/ws/v5/public');

    this.orderBookSocket.onerror = (error) => {
      console.error('Order Book WebSocket Error:', error);
    };

    this.orderBookSocket.addEventListener('open', () => {
      console.log('Order Book WebSocket connected');

      // Subscribe to order book updates
      const subscriptionMessage = {
        "op": "subscribe",
        "args": [
          {
            "channel": "books5",
            "instId": "BTC-USDT"
          }
        ]
      };

      this.orderBookSocket.send(JSON.stringify(subscriptionMessage));
    });

    this.orderBookSocket.addEventListener('message', (event) => {
      try {
        this.isLoading = true; // Set loading state to true
        const message = JSON.parse(event.data);
        if (message && message.event === 'subscribe') {
          // console.log("Subscription confirmed:", message);
        } else if (message && message.data && Array.isArray(message.data) && message.data.length > 0) {
          const orderBookData = message.data[0]; // Assuming order book data is the first element of the array
          if (orderBookData && orderBookData.asks && Array.isArray(orderBookData.asks) &&
            orderBookData.bids && Array.isArray(orderBookData.bids)) {
            this.orderBookData = orderBookData;
            this.asks = orderBookData.asks;
            // console.log(this.asks)
            this.bids = orderBookData.bids;
          } else {
            console.error("Invalid format for order book data:", orderBookData);
          }
        } else {
          console.error("Order book message data is invalid or empty:", event.data);
        }
      } catch (error) {
        console.error("Error parsing order book message:", error);
      } finally {
        this.isLoading = false; // Set loading state to false after processing
      }
    });

    this.orderBookSocket.onclose = () => {
      console.log('Connection closed');
    };

    this.pow()
    this.selectedCoin = this.$route.params.coin;
    if (this.$store.getters.isAuthenticated) {
      this.fetchOrders();
      this.$store.dispatch("setWallets");
      this.fetchMinimum(this.selectedCoin);
    }
    // this.getTradesChart();
    // this.fetchMargins();
    //Add interval
    this.interval = setInterval(() => {
      // this.getTradesChart();
      // this.fetchMargins();
    }, 10000);
  },
  //Remove/Clear interval and data when component is destroyed
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
<style scoped>
.label {
  font-size: 16px;
  color: #b7bdc6;
}

.value {
  font-size: 16px;
  font-weight: 700;
}

@media screen and (max-width: 600px) {
  .label {
    font-size: 14px;
  }

  .value {
    font-size: 14px;
    font-weight: 500;
  }
}

.toolbar-buttons {
  background-color: transparent !important;
}

.trade-container {
  width: 100vw !important;
  border-radius: 20px !important;
  background-color: #1e1e1e !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.full-width-toggle {
  display: flex;
  width: 100%;
}

.full-width-toggle .v-btn {
  flex-grow: 1;
  flex-basis: 0;
}

.v-text-field.v-text-field--solo .v-input__control {
  height: 28px !important;
}

.v-text-field.v-text-field--solo .v-input__control .v-input__slot {
  height: 28px !important;
}

.margin-title {
  font-size: 12px;
}

.margin-value {
  font-size: 14px;
}

.rating-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon-with-label {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rating-label {
  margin-top: 0px;
  font-size: 9px;
}

/* Button Spinner */
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

.right-dialog {
  left: 0;
  top: 0;
  position: fixed;
  margin: 0;
  transform: translate(0);
  width: 500px;
  height: 100vh;
  overflow-y: auto;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.vue-grid-layout {
  background: #000;
  /* height: 100vh; */
  /* height: auto !important; */
}

.vue-grid-item:not(.vue-grid-placeholder) {
  background: #2e3f49;
  /* border: 1px solid #b7bdc6; */
}

.vue-grid-item .resizing {
  opacity: 0.9;
}

.vue-grid-item .static {
  background: #cce;
}

.vue-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}

.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}

.vue-grid-item .minMax {
  font-size: 12px;
}

.vue-grid-item .add {
  cursor: pointer;
}

.vue-draggable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
  background-position: bottom right;
  padding: 0 8px 8px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
