<template>
    <v-row v-if="isLoading" justify=center>
        <v-container>
            <v-col align="center" cols="12">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-col>
        </v-container>
    </v-row>

    <v-row v-else justify="center" class="mt-1">
        <v-container>
            <v-col cols="12" xs="12" lg="12">
                <div class="d-flex mb-5">
                    <v-btn icon color="#9493AC" @click="$router.push('/wallet')" class="mr-2">
                        <v-icon small>fa-solid fa-chevron-left</v-icon>
                    </v-btn>

                    <div class="d-flex align-center justify-center sp">
                        <v-avatar size="35">
                            <img :src="coinData.image" alt="coin-icon">
                        </v-avatar>

                        <span class="ml-3">
                            {{ coinData.name }}
                        </span>

                    </div>

                </div>

                <v-sheet color="#00000000" :class="!$vuetify.breakpoint.xs ? 'mx-auto pa-8 mt-1' : ''" :style="sheetStyle">
                    <div class="d-flex justify-space-between">
                        <div class="d-flex flex-column align-start">

                            <div class="d-flex align-center">
                                <span style="font-size: 14px;">Spot Balance</span>
                                <v-btn icon x-small class="ml-2">
                                    <v-icon>mdi-eye</v-icon>
                                </v-btn>
                            </div>

                            <div class="d-flex align-center">
                                <h2 style="font-size: 34px; font-weight: 500;">
                                    {{ coinBalance.wallet_balance }}
                                    <!-- ${{ parseFloat(overviewTotal.overviewTotal).toFixed(2) }} -->
                                </h2>
                                <small class="ml-2">={{ formattedPrice(coinBalance.wallet_balance * coinPrice.close)
                                }}</small>
                                <!-- <small class="ml-2">={{ coinBalance.wallet_balance }}</small> -->
                            </div>
                        </div>

                    </div>

                    <v-row v-if="$vuetify.breakpoint.xs" style="border-bottom: 1px solid #2b3139;">
                        <v-col cols="6">
                            <div class="d-flex flex-column">
                                <span style="font-size: 11px; color: #b7bdc6">Available</span>
                                <span style="font-size: 14px; font-weight: 500;">{{ coinBalance.wallet_balance }}</span>
                            </div>

                            <div class="mt-3 d-flex flex-column">
                                <span style="font-size: 11px; color: #b7bdc6">Average Cost</span>
                                <span style="font-size: 14px; font-weight: 500;">P6.68</span>
                            </div>
                        </v-col>

                        <v-col cols="6">
                            <div class="d-flex flex-column">
                                <span style="font-size: 11px; color: #b7bdc6">Unavailable</span>
                                <span style="font-size: 14px; font-weight: 500;">{{
                                    formattedAmount(coinBalance.freeze_balance)
                                }}</span>
                            </div>

                            <div class="mt-3 d-flex flex-column">
                                <span style="font-size: 11px; color: #b7bdc6">Today's PNL</span>
                                <span style="font-size: 14px; font-weight: 500; color: #0ECB81 ;">+P717.81</span>
                            </div>
                        </v-col>
                    </v-row>

                </v-sheet>

            </v-col>

            <v-col cols="12" xs=12 lg="10">
                <v-row dense>
                    <v-col cols="12">
                        <span style="font-weight: 600; font-size: 14px;">Trading</span>
                    </v-col>

                    <v-col class="mt-2" cols="4">
                        <v-sheet width="100" @click="onTradingClick" class="pa-3" color="#00000000"
                            style="border-radius: 10px; border: 1px solid #b7bdc6 ;">

                            <div class="d-flex align-center">
                                <span style="font-size: 14px; font-weight: 500;">{{ coinData.name }}</span>
                                <span style=" font-size: 11px; color: #b7bdc6">/USDT</span>
                            </div>

                            <div class="mt-3 d-flex flex-column">
                                <span style="font-size: 14px; font-weight: 500;">{{ formattedAmount(coinPrice.close)
                                }}</span>
                                <span style="font-size: 11px; color: #0ECB81 ;">+0.02%</span>
                            </div>

                        </v-sheet>
                    </v-col>
                </v-row>
            </v-col>

            <v-col v-if="!$vuetify.breakpoint.xs" cols="12" sm="12" lg="12">
                <v-tabs v-model="tab" background-color="#00000000" bg-color="transparent" class="tabs-right">

                    <v-tab value="one">All</v-tab>
                    <v-tab-item class="pa-1">
                        <AllHistory />
                    </v-tab-item>

                    <v-tab value="two">Deposit</v-tab>
                    <v-tab-item class="pa-1">
                        <DepositHistory :depositHistory="depositHistory" />
                    </v-tab-item>

                    <v-tab value="three">Withdraw</v-tab>
                    <v-tab-item class="pa-1">
                        <WithdrawHistory :withdrawHistory="withdrawHistory" />
                    </v-tab-item>

                    <!-- <v-tab value="three">Earn</v-tab>
                    <v-tab-item class="pa-1">
                        <EarnHistory />
                    </v-tab-item> -->


                </v-tabs>
            </v-col>

            <v-col v-if="$vuetify.breakpoint.xs" cols="12" sm="12" lg="12">
                <span style="font-weight: 600; font-size: 14px;">History</span>
                <div>
                    <v-slide-group mandatory class="mt-3" v-model="selectedOption" active-class="#5E6673">
                        <v-slide-item v-for="(option, index) in options" :key="index" v-slot="{ active, toggle }">
                            <v-btn x-small :color="active ? '#FAFAFA' : '#b7bdc6 '" text class="mx-1" :input-value="active"
                                @click="toggle">
                                {{ option }}
                            </v-btn>
                        </v-slide-item>
                    </v-slide-group>
                </div>

                <div v-if="selectedOption === 0">
                    <AllHistory />
                </div>

                <div v-if="selectedOption === 1">
                    <DepositHistory :depositHistory="depositHistory" />
                </div>

                <div v-if="selectedOption === 2">
                    <WithdrawHistory :withdrawHistory="withdrawHistory" />
                </div>
                <!-- 
                <div v-if="selectedOption === 3">
                    <EarnHistory />
                </div> -->
            </v-col>
        </v-container>

    </v-row>
</template>

<script>

import AllHistory from '../History/AllHistory'
import DepositHistory from '../History/DepositHistory.vue'
import WithdrawHistory from '../History/WithdrawHistory.vue'

import DepositWithdrawHistory from '../History/DepositWithdrawHistory.vue'
import BuySellHistory from '../History/BuySell.vue'
import EarnHistory from '../History/Earn.vue'

// import { getWalletSingleCoin } from '@/services/CoinServices'
import numeral from 'numeral'

export default {

    components: {
        AllHistory,
        DepositHistory,
        WithdrawHistory,
        // DepositWithdrawHistory,
        BuySellHistory,
        EarnHistory,
    },

    data() {
        return {
            isLoading: false,
            selectedOption: 0,
            options: ['All', 'Deposit', 'Withdraw',],
            coinData: null,
            coinBalance: null,
            coinPrice: null,
            depositHistory: [],
            withdrawHistory: [],
        }
    },

    methods: {

        formattedPrice(price) {
            return numeral(price).format('$0,0.00'); // Format price as currency
        },

        formattedAmount(amount) {
            return numeral(amount).format('0,0.00'); // Format amount
        },

        onTradingClick(value) {
            const name = (value).replace('USDT', '')
            // console.log(name)
            this.$store.state.selectedCoin = name;
            this.$router.push(`/trades/trade-chart/${name}`);
        },

        async fetchCoinData() {
            try {
                this.isLoading = true
                const coinName = this.selectedCoin;
                // const coinData = await getWalletSingleCoin(coinName)
                this.$axios.get(`https://api.ubcoins.co/api/v3/wallet-single-coin`,
                    { params: { name: this.selectedCoin } })
                    .then(response => {
                        this.coinData = response.data.coin
                        this.coinBalance = response.data.balance
                        this.coinPrice = response.data.price
                        this.depositHistory = response.data.deposit
                        this.withdrawHistory = response.data.withdraw
                    })
                // this.coinData = coinData.coin
                // this.coinBalance = coinData.balance
                // this.coinPrice = coinData.price
                // this.depositHistory = coinData.deposit
                // this.withdrawHistory = coinData.withdraw
            } catch (error) {
                console.log(error)
            } finally {
                this.isLoading = false
            }
        },
        async fetchSingleCoinData() {
            console.log(this.selectedCoin)
            this.$axios.get(`https://api.ubcoins.co/api/v3/wallet-single-coin-pnl`,
                { params: { name: this.selectedCoin } })
                .then(response => {
                    console.log(response)
                })
        },
    },

    computed: {
        selectedCoin() {
            return this.$store.state.selectedCoin;
        },

        sheetStyle() {
            if (this.$vuetify.breakpoint.xs) {
                // If the screen size is xs, return an empty object to remove the style
                return {};
            } else {
                // If the screen size is not xs, return the default style
                return {
                    borderRadius: '5px',
                    border: '1px solid #b7bdc6 '
                };
            }
        }
    },

    mounted() {
        this.fetchCoinData()
        this.fetchSingleCoinData()
    }
}
</script>

<style></style>